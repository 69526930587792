// COLORS
$color-white: #fff;
$color-black: #000;
$safe-green: #8ac400;
$safe-blue: #185da9;

$safe-blue-light: #5e8dc3;

$grey-dark: #7f7f7f;
$grey: #aaa;
$grey-light: #e5e5e5;
$grey-lighter: #ebebeb;
$grey-lightest: #f4f4f4;
$font: $grey-dark;
$basefont: 'Safe Sans', sans-serif;
$font-small: calc(10px + .2rem);
$font-medium: calc(13px + .2rem);

$remPixelFactor: 16;
$remPostDecimalPositionNumber: 1000;

$maxPagewidth: 1900px;
$bp-mobile: 1199px;
$bp-mobile-s: 649px;
$bp-mobile-xs: 420px;

$pagePadding: var(--gap6);
$pagePadding-neg: calc(#{$pagePadding} * -1);
$mobilePadding: var(--gap2);

:root {
    --rem: 1.0535vw;

    @media screen and (min-width: $maxPagewidth) {
        --rem: 20px;
    }

    @media screen and (max-width: $bp-mobile) {
        --rem: 1.5vw;
    }

    @media screen and (max-width: 767px) {
        --rem: 2.5vw;
    }

    @media screen and (max-width: $bp-mobile-s) {
        --rem: 3.25vw;
    }

    @media screen and (max-width: $bp-mobile-xs) {
        --rem: 4vw;
    }

    @media screen and (max-width: 320px) {
        --rem: 11px;
    }

    --reSize: 100%;
    --gap: calc(var(--rem) / 1.5);
    --gap2: calc(var(--gap) * 2);
    --gap3: calc(var(--gap) * 3);
    --gap4: calc(var(--gap) * 4);
    --gap5: calc(var(--gap) * 5);
    --gap6: calc(var(--gap) * 6);
    --gap12: calc(var(--gap) * 12);
}
