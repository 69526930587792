article {
    max-width: 100%;

    .article-header {
        .left {
            display: flex;
            align-content: flex-end;
            flex-wrap: wrap;

            > * {
                flex: 1 0 100%;
            }

            > div:first-child,
            .event-date {
                @include square;

                white-space: normal;
                overflow: visible;
                margin-top: 1rem;
                margin-bottom: 1.5rem;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid transparentize($font, .75);
            }

            .event-date{
                margin-top: 0;

                @media screen and (max-width: 430px){
                    flex-wrap: wrap;

                    .date.end{
                        flex: 1 0 100%;
                        padding-left: 1.75em;
                    }
                }
            }

            h3,
            h4 {
                margin: 0;

                + * {
                    margin-top: .81rem;

                    &:empty {
                        margin: 0;
                    }
                }
            }
        }

        h1 {
            flex: 1 0 100%;
            margin: 0 0 1em;
        }

        h2 {
            flex: 1 0 100%;
            margin-bottom: 0;

            @media screen and (min-width: 960px) and (max-width: 1199px) {
                margin-bottom: 1.5rem !important;
            }
        }

        .main {
            display: flex;
            flex-wrap: wrap;

            .imageBox {
                display: flex;
                flex-wrap: wrap;
                flex: 1;
                background: url("../../Images/bmp/paper-green.jpg") no-repeat $safe-green;
                background-size: cover;

                @media screen and (max-width: 767px) {
                    margin: 0 -1.5rem;
                }

                @media screen and (min-width: 1200px) {
                    min-height: 25rem;
                }

                * {
                    color: #fff;
                }

                .content {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    padding: 3rem 3rem 9rem;

                    h1,
                    h2,
                    h3 {
                        margin-bottom: 0;
                    }

                    .cat {
                        align-self: flex-start;
                        margin-bottom: 1rem;
                    }

                    @media screen and (min-width: 1200px) {
                        max-width: 100%;
                        padding: 3rem 3rem 9rem;
                    }

                    @media screen and (max-width: 959px) {
                        padding: 3rem;
                    }

                    @media screen and (max-width: 767px) {
                        padding: 1.5rem;

                        h1,
                        .h1 {
                            font-size: 1.5rem;
                        }
                    }
                }
            }

            img {
                max-width: none;
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;

                @media screen and (max-width: 767px) {
                    width: calc(100% + calc(#{$mobilePadding} * 2));
                    margin: 0 calc(#{$mobilePadding} * -1);
                }
            }
        }

        @media screen and (max-width: 959px) {
            .main {
                order: -1;
                margin-bottom: 1.5rem;
            }
        }
    }

    .article-body {
        position: relative;
        z-index: 3;
        margin-top: 0 !important;

        aside.left {
            display: flex;
            flex-wrap: wrap;
            margin-top: 6rem;
            align-items: flex-start;

            .sideImage,
            .box-square {
                flex: 1 0 100%;
                max-width: 100%;
                display: block;
            }

            .box-square {
                @media screen and (max-width: 959px) {
                    display: none;
                }
            }

            .sideImage {
                background: #fff;

                figure {
                    display: block;

                    figcaption {
                        font-size: $font-small;
                        margin-top: .5rem;
                    }

                    img {
                        border: 6px solid #e6e6e6;
                        margin: 0;
                        width: 100%;
                    }
                }

                @media screen and (min-width: 1200px) {
                    max-width: 50%;
                    margin-top: -3rem;
                    margin-bottom: 3rem;
                }

                @media screen and (max-width: 1199px) {
                    margin-bottom: 3rem;
                }

                @media screen and (max-width: 959px) {
                    display: none;
                }
            }

            @media screen and (max-width: 959px) {
                order: 2;
                margin-top: 3rem;
            }

            @media screen and (min-width: 960px) and (max-width: 1199px) {
                padding-left: 0;
                margin-top: 3rem;
                width: calc(33.32223% - 1.5rem);
            }

            @media screen and (min-width: 960px) {
                position: sticky;
                top: 3rem;
            }
        }

        .main {
            @media screen and (min-width: 960px) {
                margin-top: -6rem;
                background: #fff;
                transform: translateX(-1px);
            }

            @media all and (min-width: 1200px) {
                background: transparent;
            }
        }

        .main-content {
            margin-top: 2rem;


            @media screen and (min-width: 960px) {
                padding-top: 1.5rem;
                padding-right: 1.5rem;
                background: #fff;
                margin-top: 0;
                padding-bottom: 0;
            }

            @media all and (min-width: 1200px) {
                padding-top: 2rem;
                padding-right: 2rem;
                margin-right: 25%;

                .overshoot {
                    margin-right: -40%;
                }
            }

            img {
                max-width: none;
                width: 100%;
            }

            p {
                margin: 0 0 1.618em;

                &:first-child {
                    &:first-letter {
                        float: left;
                        line-height: 1;
                        background: $safe-green;
                        font-weight: 700;
                        text-transform: uppercase;
                        color: #fff;
                        padding: .5rem 1rem;
                        margin: .5rem 1.5rem .5rem 0;
                        font-size: 2.25rem;
                    }
                }
            }

            ul:not(.nav),
            ol {
                padding-left: 3rem;

                li {
                    list-style: square outside;
                    font-size: 1.05rem;
                    margin-bottom: .81em;
                }
            }

            > *:last-child {
                margin-bottom: 0 !important;
            }
        }
    }

    .date {
        text-transform: none;
    }

    .date-divider {
        padding: 0 .25em;
    }

    .event-info {
        .event-organizer {
            font-weight: 600;
        }
    }

    &.eventdetail{
        @media screen and (min-width: 960px) and (max-width: 1199px){
            margin-top: 3rem;
        }
    }
}

article {
    &.publication {
        .article-body {
            .main {
                @media screen and (min-width: 960px) and (max-width: 1199px) {
                    transform: none;
                }

                .main-content {
                    @media screen and (min-width: 1200px) {
                        padding-bottom: 0;
                    }
                }
            }

            aside.left {
                margin-top: 0;

                @media screen and (max-width: 1199px) and (min-width: 960px) {
                    padding-left: 1.5rem;
                    width: 33.32223%;
                }
            }
        }

        .article-header {
            .publication-details {
                margin-bottom: 0;

                .col {
                    display: flex;

                    .item {
                        font-size: $font-small;
                        flex: 1;

                        h4 {
                            margin-bottom: .5em;
                        }
                    }

                    @media screen and (min-width: 1200px) {
                        .item {
                            padding-bottom: 1.5rem;
                            border-bottom: 1px solid transparentize($font, .75);
                        }
                    }

                    @media screen and (max-width: 1199px) and (min-width: 650px) {
                        .item {
                            padding-right: 1.5rem;
                            border-right: 1px solid transparentize($font, .75);
                        }
                    }

                    @media screen and (max-width: 649px) {
                        .item {
                            padding-bottom: 1.5rem;
                            border-bottom: 1px solid transparentize($font, .75);
                        }
                    }

                    &:last-child {
                        .item {
                            border: 0;
                        }
                    }
                }
            }
        }
    }
}

