.ce-uploads {
    padding-left: 0;
    margin-left: 0;

    li {
        list-style: none;
        margin-bottom: .5rem;

        > div {
            a {
                display: flex;
                align-items: center;
                font-size: $font-small;

                &:before {
                    display: block;
                    content: "";
                    background: url("../../Images/icons/download_grey.svg") no-repeat center center;
                    background-size: contain;
                    min-width: 3em;
                    min-height: 3em;
                    margin-right: 1em;
                }

                span.ce-uploads-fileName {
                    line-height: 1.25;
                    max-width: 65ch;
                }
            }
        }
    }
}
