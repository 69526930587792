.nav-list {
    li:not(.active) {
        a {
            &:hover {
                color: $safe-blue;
            }
        }
    }
}

.box-square {
    .uk-nav,
    .nav-list {
        display: block;
        margin-bottom: 0 !important;
        margin-top: -.5rem;

        ul.uk-nav-sub {
            padding: 0;

            li {
                font-weight: 500;
            }
        }

        > li {
            border-bottom: 1px solid transparentize($font, .5);
            font-weight: 600;

            &.uk-active,
            &.active {
                font-weight: 600;

                > a {
                    color: $safe-green;
                }
            }

            &:last-child {
                border: none;
            }

            > a {
                padding: .5rem 0;
                border: none !important;
            }

            &.sub {
                .uk-nav[class*="lvl-"],
                .nav-list[class*="lvl-"] {
                    padding: .5rem 0;

                    li {
                        border: none;
                        font-weight: 500;

                        a {
                            padding: .25rem 0 .25rem 1rem;
                            font-size: .9rem;
                        }
                    }
                }
            }
        }
    }
}

#main-content main .md-container,
.md-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    padding: 0;

    .headline {
        font-size: $font-small;
        font-weight: 600;
        color: #fff;
        margin: 1.2rem 0 .5rem;
        position: relative;
        z-index: 10;
    }

    @media screen and (max-width: 1199px) {
        position: relative;
        margin-top: 3rem;
        z-index: 300;

        .nav-section-mobile{
            margin: 1rem 0;
        }

        &:before {
            background: $safe-blue;
            content: "";
            position: absolute;
            z-index: 1;
            width: 100vw;
            left: 50%;
            margin-left: -50vw;
            height: 100%;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .headline {
            margin: 0;
            font-size: $font-medium;
        }
    }
}

.nav-section-mobile {
    display: none;

    > .uk-button {
        text-align: left;
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1;
        font-weight: 900;
        display: flex;
        flex: 1 0 100%;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        margin-bottom: 0;

        .icon {
            display: flex;
            align-items: center;
            background: url(../../Images/icons/icon-list-grey.svg) no-repeat 100%;
            width: 1.25rem;
            height: 1.25rem;
            margin-left: 1rem;
        }
    }

    .uk-dropdown {
        width: 100%;
        box-shadow: 0 30px 18px -15px rgba(0, 0, 0, .3);
        background: $grey-lightest;
        padding-top: 0;
        padding-bottom: 0;

        ul.nav-list {
            flex-direction: column;
            white-space: nowrap;
        }

        > ul.nav-list {
            flex-direction: column;
            white-space: nowrap;

            > li {
                border-bottom: 1px solid transparentize($font, .75);

                &:last-child {
                    border: none;
                }

                &.active {
                    > a {
                        color: $safe-green;
                    }
                }
            }

            ul.nav-list {
                li {
                    border: none;

                    > a {
                        padding: .25rem .5rem .25rem 1.5rem;
                    }

                    &.active {
                        > a {
                            color: $safe-green;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1199px) {
        display: flex;
        position: relative;
        max-width: calc(50% - .75rem);
        flex: 1 0 50%;
        z-index: 10;

        > .uk-button .icon {
            background-image: url(../../Images/icons/chevron-down_grey.svg);
            width: 1rem;
            height: 1rem;
        }
    }

    @media screen and (max-width: 767px) {
        max-width: 100%;
        flex: 1 0 100%;
        display: block;

        .uk-button {
            width: 100%;
        }
    }
}

#main-content {
    &.detailview {
        @media screen and (min-width: 960px) {
            .md-container {
                display: none !important;
            }

            .box-square {
                display: block !important;
            }
        }
    }
}
