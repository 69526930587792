table,
table.dataTable {
    width: auto;
    max-width: 100%;
    border-spacing: 2px;
    margin: -2px;
    margin-bottom: 3rem;
    font-size: $font-medium;
    border: none;

    thead,
    tbody,
    th,
    tr,
    td{
        border: none;
    }

    p {
        font-size: $font-medium;
    }

    @media screen and (max-width: 767px) {
        font-size: 13px;
    }

    thead {
        background: $grey;
        color: #fff;
        font-size: 1rem;
        line-height: 1.1;
        font-weight: 600;
        text-transform: uppercase;
        border: none;

        @media screen and (max-width: 767px) {
            font-size: 15px;
        }

        tr {
            th {
                text-align: left;
                padding: 1rem 1.5rem;

                @media screen and (max-width: 1199px) {
                    padding: 1rem;
                }
            }
        }
    }

    tbody {
        tr.thead{
            background: $safe-green;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 1.2;

            th{
                padding: .5rem;
            }
        }

        tr {
            &.even {
                background: $grey-lightest;
            }

            td {
                padding: 1rem 1.5rem;

                > a {
                    @include link;
                }

                @media screen and (max-width: 1199px) {
                    padding: 1rem;
                }
            }
        }
    }

    &[bordercolor] {
        border-spacing: 0;
        border: 1px solid $grey;

        tbody {
            tr {
                td{
                }
            }
        }
    }
}

table.contenttable {
    thead {
        tr td {
            padding: 1rem 1.5rem;
            font-weight: 700;
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                background: $grey-lightest;
            }

            td {
                padding: 1rem 1.5rem;

                > a {
                    font-weight: 500;

                    @include link;
                }

                @media screen and (max-width: 1199px) {
                    padding: 1rem;
                }
            }
        }
    }
}
