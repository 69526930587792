
.tabslider {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;

    --captionWidth: 26.65%;

    .tabnav {
        display: flex;
        flex-wrap: wrap;
        flex: 2;
        margin-right: calc(4.5rem * -1);
        position: relative;
        z-index: 1;

        ul.nav {
            flex-direction: column;
            flex: 1 0 100%;
            max-height: 50%;

            @media screen and (min-width: 1200px){
                max-height: calc(50% - 1.5rem);
            }

            li {
                padding-left: 0;
                border-bottom: 1px solid $grey-lighter;
                display: flex;
                margin-right: 3rem;

                &:last-child {
                    border: none;
                }

                a {
                    position: relative;
                    overflow: hidden;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    transition: all .25s;
                    font-size: 1.25rem;
                    line-height: 1.2;
                    border: none !important;
                    font-weight: 300;
                    padding-right: 1rem;
                    &:before{
                        position: absolute;
                        z-index: 1;
                        content: "";
                        background: $safe-green;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        transform: translateX(-110%);
                        transition: all .25s;
                    }
                    &:after {
                        content: none;
                    }

                    span {
                        color: currentColor;
                        position: relative;
                        z-index: 2;
                    }

                    &.uk-active {
                        color: #fff;
                        padding-left: 1rem;
                        font-weight: 700;
                        transition-delay: .4s;
                        &:before{
                            transform: none;
                            transition-delay: .3s;
                        }
                    }
                }
            }
        }
    }

    .h1 {
        @include sectionHeadline;

        margin: 0 9rem 0 0;
        transform: translateY(3rem);
        align-items: flex-start;

        &:after {
            content: none;
        }
    }

    .sliderArrows {
        position: absolute;
        bottom: 0;
        left: var(--captionWidth);
        margin-left: 2.25rem;
    }

    .slide-container {
        overflow: hidden;
        padding: 0;
        flex: 6;
        position: relative;
        z-index: 2;
    }

    .uk-slideshow-items {
        .item {
            width: 100%;
            padding-left: 4.5rem;

            img {
                width: 100%;
                height: auto;
                max-width: none;
            }

            .item-caption {
                color: #fff;
                background: $safe-blue;
                width: var(--captionWidth);
                padding-bottom: var(--captionWidth);
                position: absolute;
                left: 0;
                bottom: 0;
                transition: all .5s;
                opacity: 1;
                transform: translateX(-100%);

                > .content {
                    color: #fff;
                    position: absolute;
                    padding: 1.5rem;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    overflow: hidden;

                    > * {
                        position: relative;
                        z-index: 2;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        background: $safe-green;
                        transform: translate(110%, 0);
                        transition: transform .35s ease-out;
                    }

                    &:hover {
                        &:before {
                            transform: none;
                        }
                    }

                    .slider-tag {
                        flex: 1;
                        text-align: right;
                        font-weight: 800;
                        line-height: 1.2;
                        font-size: .85rem;
                        text-transform: uppercase;
                        transition: color .25s .15s;

                        @media screen and (max-width: 679px) {
                            text-align: left;
                        }
                    }

                    p {
                        margin-top: auto;
                        font-size: 1.4rem;
                        font-weight: 300;
                        line-height: 1.2;

                        @media screen and (min-width: 1200px){
                            font-size: 1.25rem;
                            line-height: 1.35;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.uk-transition {
                .item-caption {
                    transform: translateX(-100%);
                    opacity: 0 !important;
                }
            }

            &.uk-transition-active {
                .item-caption {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }
    }
}

@media screen and (max-width: $bp-mobile) {
    .tabslider {
        --captionWidth: 50%;

        position: relative;

        .slide-container {
            flex: 1 0 100%;

            .sliderArrows {
                display: none;
            }
        }

        .h1 {
            order: -1;
            transform: translate(3rem, -3rem);
            color: #fff;
            font-size: 4.5rem;
            line-height: .85;
        }

        .tabnav {
            order: 3;
            flex: 1 0 50%;
            max-width: 50%;
            transform: none;
            z-index: 10;
            margin: 0;
            position: absolute;
            bottom: 0;

            ul.nav {
                max-height: none;
                background: #fff;

                li {
                    a {
                        min-height: 2.888rem;
                    }
                }
            }
        }

        .uk-slideshow-items {
            min-height: 66vw !important;

            .item {
                padding-left: 0;

                .item-caption {
                    left: auto;
                    right: 0;
                    bottom: 0;
                    transform: none;
                    opacity: 1 !important;
                    transition: none;

                    > .content {
                        padding: 3rem;
                        transition: opacity .35s;
                        opacity: 0;

                        p {
                            font-size: 2em;
                        }
                    }
                }

                &.uk-transition {
                    .item-caption {
                        transform: none;
                        opacity: 1 !important;

                        > .content {
                            opacity: 0;
                        }
                    }
                }

                &.uk-transition-active {
                    .item-caption {
                        opacity: 1 !important;
                        transform: none;

                        > .content {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1023px) and (min-width: 768px) and (orientation: portrait) {
    .tabslider {
        .uk-slideshow-items {
            min-height: 75vw !important;
        }

        .h1 {
            transform: translate(3rem, -2.4rem);
            font-size: 4rem;
        }

        .tabnav ul.nav li a {
            font-size: 1.4rem;
            line-height: 2.95;
        }
    }
}

@media screen and (max-width: 767px) {
    .tabslider {
        --captionWidth: 33.3334%;

        font-size: 2vw;

        .slide-container {
            .sliderArrows {
                display: flex;
                left: auto;
                right: 0;
            }
        }

        .h1 {
            white-space: nowrap;
            margin: 0;
            padding: 0;
            transform: translate(1.5rem, -4.5rem);
            font-size: 3.75em;
        }

        .tabnav {
            height: 50%;
            pointer-events: none;

            ul.nav {
                display: none;
                pointer-events: none;
            }
        }

        .uk-slideshow-items {
            min-height: 65vw !important;

            .item {
                .item-caption {
                    left: 0;
                    right: auto;
                    width: 66.66667%;
                    transform: translateX(-100%);
                    transition: all .5s;
                    opacity: 0 !important;

                    > .content {
                        padding: 1.5rem;

                        p{

                        }
                    }

                    &.uk-transition {
                        .item-caption {
                            transform: translateX(-100%);
                            opacity: 0 !important;
                        }
                    }

                    &.uk-transition-active {
                        .item-caption {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $bp-mobile-s) {
    .tabslider .uk-slideshow-items .item .item-caption > .content p {
        font-size: $font-medium;
    }
}

@media screen and (max-width: 479px) {
    #main-content > .frame.frame-type-mmthemesafe_carouseltabs {
        padding-bottom: 1.5rem;
    }
    .tabslider {
        .h1 {
            transform: translate(1.5rem, -1.5rem);
        }

        .uk-slideshow-items {
            min-height: 75vw !important;

            .item {
                .item-caption {
                    width: calc(100% - 6.5rem);
                }
            }
        }
    }
}

@media screen and (max-width: 420px) {
    #main-content > .frame.frame-type-mmthemesafe_carouseltabs {
        padding-bottom: 0;
    }

    .tabslider {
        .uk-slideshow-items {
            min-height: 75vw !important;

            .item {
                .item-caption {
                    width: 100%;
                    > .content{
                        padding-top: 1rem;
                        .slider-tag{
                            font-size: 12px;
                        }
                        p{
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .slide-container .sliderArrows {
            display: none;
        }
    }
}
