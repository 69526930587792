#footer {
    background: $safe-blue;
    color: #fff;

    @include sectionFullwidth;

    padding: $pagePadding;

    @include bs2uk-grid;

    @media all and (max-width: 767px) {
        margin: 0 calc(#{$mobilePadding} * -1);
        padding-bottom: 5rem;
    }

    .footer-content {
        position: relative;
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5 {
        color: $safe-green;
    }

    header {
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3 {
            @include sectionHeadline;

            color: $safe-green;
            margin-top: 0;
            margin-bottom: .75rem;
            line-height: 1;
        }

        h5,
        .h5 {
            font-size: 1.5rem;
            font-weight: 800;
            text-transform: uppercase;
            margin-bottom: .75rem;
            line-height: 1;
        }
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    ul {
        padding: 0;

        li {
            list-style: none;

            a {
                color: #fff;
                font-weight: 300;
                padding-bottom: .75rem;
                display: block;

                &:hover,
                &:focus,
                &:active {
                    color: $safe-green;
                }
            }
        }
    }

    .footer-left {
        @media screen and (min-width: 768px) {
            max-width: 50%;
        }

        p {
            font-weight: 300;
        }

        header {
            + p {
                margin-top: 0;

                strong {
                    font-weight: 600;
                    max-width: 24ch;
                    display: block;
                    line-height: 1.25;
                }
            }
        }

        .frame {
            > *:last-of-type {
                margin-bottom: 0;
            }

            @media screen and (min-width: 1200px) {

            }

            @media screen and (min-width: 430px)  and (max-width: 767px) {
                max-width: 50%;
            }
        }

        @media screen and (max-width: 767px) {
            > div:first-child {
                border-bottom: 1px solid #fff;
                padding-bottom: var(--gap4);
                margin-bottom: var(--gap4);
            }
        }
    }

    .footer-right {
        margin-left: auto;

        .row > .col-sm-6 {
            margin-top: 0;

            @media screen and (max-width: 429px) {
                flex: 1 0 100%;
                max-width: 100%;

                ul li a {
                    padding-bottom: .5rem;
                }
            }
        }
    }

    .footer-content,
    .footer-menu {
        max-width: $maxPagewidth;
        margin: 0 auto;
    }

    .footer-menu {
        border-top: 1px solid #fff;
        padding-top: var(--gap6);
        margin-top: var(--gap6);

        @media screen and (max-width: 767px) {
            padding-top: var(--gap4);
            margin-top: var(--gap4);
            margin-bottom: var(--gap4);
        }

        .uk-grid {
            > * {
                display: flex;
                align-items: center;
            }
        }

        ul li a {
            padding-bottom: 0;
        }
        .footer-copyright{
            white-space: nowrap;
        }

        @media screen and (max-width: 767px) {
            .f-copy {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                background: transparentize(#fff, .9);
                justify-content: center;
                padding: .5rem var(--gap2);
                font-size: 12px;
            }

            .f-logo {
                display: none;
            }

            .f-nav {
                order: -1;
                flex: 1 0 100%;
            }
        }

        @media screen and (max-width: 429px) {
            .f-logo {
                display: block;
                flex: 1 0 100%;

                .leibniz-logo {
                    margin-left: auto;
                    margin-right: auto;
                    min-height: 80px;
                }
            }
        }
    }
    .promoted{
        position: relative;
        display: flex;
        margin-left: auto;

        @media (max-width: 767px){
            flex-wrap: wrap;
            justify-content: flex-end;
            text-align: right;
        }
        &:before{
            content: "Gefördert durch:";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            line-height: 1;
            transform: translateY(-100%);
            margin-top: -.75em;

            @media (max-width: 767px){
                position: static;
                flex: 1 0 100%;
                transform: none;
                margin-top: 0;
                margin-bottom: 8px;
                font-size: 10px;
            }
        }
    }
    .leibniz-logo, .partner-logo {
        height: 8vw;
        max-height: 120px;
        min-height: 50px;
        &.bmwi{
            margin-left: var(--gap);
        }
        img,
        svg {
            max-width: none;
            width: 100%;
            height: 100%;
        }

        &.logo-mobile {
            display: none;
        }

        @media all and (min-width: 430px) and (max-width: 767px) {
            &.leibniz-logo{
                display: none;
            }
            &.logo-mobile {
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: 25vw;
                height: auto;
                max-width: 50%;
            }
        }
    }

    ul.footer-menu-list {
        display: block;
        text-align: right;
        margin-left: var(--gap2);
        li {
            display: block;
            margin-right: var(--gap2);

            a {
                padding-bottom: 0;

                &[title="Home"] {
                    display: none;
                }
            }

            @media (min-width: 768px) and (max-width: 1199px){
                display: block;
                &:last-child {
                    margin-right: var(--gap2);
                }
                a{

                }
            }
        }
    }

    .f-nav {
        @media screen and (max-width: 767px) {
            padding-left: 0;

            ul.footer-menu-list {
                text-align: left;
            }
        }
    }

    .scrollTop {
        border-radius: 4rem;
        background: transparent;
        border: 1px solid #fff;
        margin-left: var(--gap2);
        color: #fff;
        max-width: 40px;
        max-height: 40px;

        svg {
            + svg {
                display: none;
            }
        }

        &:hover {
            background: #fff;
            color: $font;
        }
    }
}

html[lang="en-US"]{
    .leibniz-logo .logo-de{
        display: none !important;
    }
    #footer .promoted:before {
        content: "Funded by:";
    }
}
html[lang="de-DE"]{
    .leibniz-logo .logo-en{
        display: none !important;
    }
}
.social-bar {
    @include sectionFullwidth;

    color: #fff;
    margin-top: 6rem !important;
    padding: 1.5rem $pagePadding;
    background: $safe-green;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    header{
        flex: 1;
    }

    h1,
    .h1 {
        @include sectionHeadline;

        color: #fff;
        margin: 0;
        line-height: 1;
    }

    @media all and (max-width: 767px) {
        margin: 0 calc(#{$mobilePadding} * -1);
        width: auto;
        max-width: none;
    }
}

#main-content.home + .social-bar {
    margin-top: 0 !important;
}

.social-links {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    padding-left: 3rem;

    @media screen and (max-width: 767px) {
        justify-content: flex-start;
        flex: 1 0 100%;
        margin-top: 1.5rem;
        padding-left: 0;
    }

    .uk-icon-button {
        width: 4.5rem;
        height: 4.5rem;
        margin-right: 1.5rem !important;
        background: transparentize(#fff, .7);
        color: #fff;
        transition: all .35s;

        &:last-child {
            margin-right: 0 !important;
        }

        svg {
            width: 2.25rem;
            transition: transform .75s;
        }

        &:hover {
            background: transparent;

            svg {
                transform: scale(1.45);
            }
        }
    }
}

#footer .leibniz-logo, #footer .partner-logo {
    @media (max-width: 767px) {
        height: 50px;
        max-height: none;
        min-height: 0;
        width: auto;
    }
}

#footer .leibniz-logo img, #footer .partner-logo img {
    width: auto !important;
    object-fit: contain !important;
}
