* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    a:active {
        transition: none !important;
    }
}

body,
html {
    font-size: var(--rem);
    color: $font;
}

body {
    background: $grey-lighter;
    min-width: 320px;

    *:focus {
        outline: none;
    }

    &.resizing {
        #wrapper-outer {
            background: $safe-blue;
            transition: none;
        }

        #wrapper-inner {
            opacity: 0;
            transition: none;
        }
    }

    scrollbar-color: $font transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        background: $grey-lightest;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: $font;
        transition: background-color .35s;
        cursor: ns-resize;

        &:hover {
            background-color: $safe-green;
        }
    }
}

#wrapper-inner {
    max-width: $maxPagewidth;
    padding: $pagePadding $pagePadding 0;
    margin: 0 auto;
    background: #fff;
    transition: background-color .3s;

    @media screen and (max-width: 1200px) {
        box-shadow: 0 0 40px rgba(0, 0, 0, .25);
    }

    @media screen and (min-aspect-ratio: 157/90) and (max-width: 1960px) {
        padding: var(--gap3) $pagePadding 0;
    }

    @media screen and (min-width: $maxPagewidth) {
        //box-shadow: 0 0 40px transparentize(#000, 0.9);
    }

    @media screen and (max-width: 767px) {
        padding: 0 $mobilePadding;
        overflow-x: hidden;
        overflow-y: auto;

        #main-content.home {
            margin: 0 calc(#{$mobilePadding} * -1);
            width: auto;
            max-width: none;
            overflow: hidden;
        }

        .content-page {
            padding: 0;
        }
    }
}

#wrapper-inner {
    transition: opacity .3s;
}

ul.nav {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin: 0;
    padding: 0;

    li {
        flex: 1;
        list-style: none;

        a {
            display: block;
        }
    }
}

.well {
    min-height: 0 !important;
    height: auto !important;
}

