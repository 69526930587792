.ie {
    @media all and (min-width: 1200px) {
        body {
            font-size: 1vw !important;
            overflow-x: hidden;

            .stickyHeader {
                position: static;
                transform: none;
                width: 100%;
                max-width: 100% !important;
            }

            #wrapper-inner {
                padding: 60px 60px 0;
            }

            .container,
            [class*="col-"] {
                padding: 0 60px;
            }

            [class*="sectionGrey"] {
                margin: 0 -60px;
                padding: 0 60px;
            }

            section.container {
                padding-left: 0;
                padding-right: 0;
            }

            section,
            section .frame {
                max-width: 100%;
            }

            section.home {
                max-width: none;
                padding-left: 0;
                padding-right: 0;

                .frame {
                    max-width: none;
                }
            }

            .frame-sectionblue {
                margin: 0 -60px;
                padding: 0 60px;
            }

            main {
                width: 100%;
            }

            .social-bar {
                margin: 0 -60px;
                padding: 20px 60px;
            }

            #footer {
                margin: 0 -60px;
                padding: 60px;

                .scrollTop {
                    margin-left: 1rem;
                }

                .footer-menu {
                    padding-top: 60px;
                    margin-top: 60px;
                }

                ul.footer-menu-list li {
                    margin-left: 1rem;
                }
            }

            #footer .leibniz-logo svg,
            .social-links .uk-icon-button svg {
                height: 100%;
            }

            .topbar {
                nav.nav-lang {
                    min-width: 55px;
                }

                .nav-top-extern {
                    min-width: 200px;

                    ul.nav li {
                        display: block;
                        text-align: right;

                        &:after {
                            content: none !important;
                        }
                    }
                }
            }

            .tabslider {
                .uk-slideshow-items .item .item-caption {
                    width: 300px;
                    height: 300px;
                }

                .sliderArrows {
                    left: 300px;
                }
            }

            table,
            table.dataTable {
                max-width: 400px;

                .dt-title .title-content {
                    display: block;
                }
            }

            table.dataTable {
                .dt-author a {
                    display: inline-block;
                }
            }

            .btn {
                height: 3rem;
            }

            .select-wrapper {
                padding-right: 0;

                &:after {
                    content: none;
                }
            }

            .boxes-bottom {
                flex: 1 0 100%;
            }

            .panel-topics {
                .panel-collapse .dataTables_wrapper {
                    width: 100%;
                }

                table.table-topics.dataTable {
                    tbody,
                    tr {
                        display: block;
                        width: 100%;
                    }
                }
            }

            article {
                .article-header .main {
                    .imageBox {
                        flex: 1 0 100%;

                        .content {
                            flex: 1;
                        }
                    }
                }
            }
        }
    }

    @media all and (min-width: 1920px) {
        body {
            font-size: 20px !important;
        }
    }

    @media all and (max-width: 1199px) {
        body {
            display: flex;
            flex: 1 0 100%;
            min-height: 100vh;
            align-items: center;
            justify-content: center;
            background: $safe-green;
            position: relative;

            &:after {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 50px;
                line-height: 1.25;
                padding: 30px;
                content: "Please resize your Browser-Window";
                display: block;
            }
        }

        .mm-page,
        #wrapper-outer {
            display: none;
        }
    }
}


