.newsslider {
    @include grid-small;

    h2 {
        font-size: 1em;
        line-height: 1.2;
    }

    p {
        font-size: .9em;
        line-height: 1.35;
        font-weight: 300;

        @media screen and (max-width: 767px) and (min-width: 650px) {
            display: none;
        }
    }
}
