.blogslider {
    h2,
    .h2 {
        line-height: 1.25;
    }

    .teaser-text {
        p {
            font-weight: 300;
        }
    }

    .news-img-wrap {
        margin-right: 0;
        margin-bottom: 1.5rem;
        overflow: hidden;

        img {
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 1s;
            will-change: transform;
        }
    }

    .item-content {
        &:after {
            padding-bottom: 125%;
        }
    }

    @media screen and (max-width: 767px) {
        .teaser-text {
            display: none;
        }
    }
}
