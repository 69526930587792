.cards {
    margin-bottom: 3rem;

    @media screen and (max-width: 750px){
        &[class*="uk-child-width"] > * {
            width: 100%;
        }
    }
}

.card {
    display: flex;
    overflow: hidden;
    background: $grey-lightest;
    position: relative;
    padding: .75rem;
    margin-bottom: 1.5rem;

    .card-image {
        flex: 1;
        align-self: flex-start;
        border: .4rem solid #fff;
        background: #fff;
        margin-right: .75rem;

        img {
            max-width: none;
            width: 100%;
        }
    }

    .card-content {
        position: relative;
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .name {
            font-size: .9rem;
            font-weight: 600;
            margin: 0 0 .25rem;
            line-height: 1.2;
        }

        .position {
            font-style: italic;
            font-weight: 400;
            font-size: $font-small;
            margin: 0;
            line-height: 1.2;
        }
    }

    .icon {
        transform: translate(.55rem, -.75rem);
        margin: .75rem .75rem 0 0;
        align-self: flex-end;
        width: 2.25rem;
        height: 2.25rem;
        background: url("../../Images/icons/contact-person.svg") no-repeat center center;
        background-size: contain;
    }

    .icon-buttons {
        display: flex;
        margin: 1rem 0 0;

        a {
            width: 2.25rem;
            height: 2.25rem;
            color: $safe-green;
            background: #fff;
            margin-right: .75rem;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                color: #fff;
                background: $safe-green;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &.card-author {
        padding: 1.5rem;

        @media all and (min-width: 1200px) {
            max-width: 66.66667%;
        }

        h1 {
            font-size: 1.25rem;
            font-weight: 700;
            margin: 0 0 .5rem;
            text-transform: none;
            letter-spacing: 0;
            line-height: 1.25;
            color: $font;
        }

        .card-image {
            margin-right: 1.5rem;
        }

        .card-info {
            div {
                font-weight: 700;
                font-size: $font-small;
                margin-top: .5rem;
            }

            span {
                display: block;
                font-weight: 300;
            }
        }

        .icon-buttons {
            margin-top: .4rem;
        }

        @media screen and (max-width: 549px) {
            flex-direction: column;

            .card-image {
                margin: 1rem 0 0;
                width: 100%;
                order: 3;
            }
        }
    }
}

.card-accordion {
    ul.uk-accordion {
        margin-bottom: 0;
        padding-bottom: 0;
        min-width: 100%;
    }

    .uk-accordion-title {
        font-size: 1.25rem;
        font-weight: 500;
        background: transparent;
        border-bottom: 1px solid transparentize($font, .75);

        &:before {
            margin-right: 10px;
            margin-left: 0;
            float: left;
        }

        @media all and (min-width: 1200px) {
            max-width: 66.66667%;
        }

        &:hover{
            background-color: $grey-lightest;
        }
    }
}
