.burger {
    display: none;
}

#nav-main {
    display: flex;
    flex: 1;
    background: #f5f5f5;

    .uk-navbar-container {
        flex: 1 0 75%;
    }

    [class*=uk-dropdown-bottom] {
        margin-top: 0;
    }

    ul.nav.uk-navbar-nav {
        > li {
            border-right: .15rem solid #fff;

            &.Home {
                display: none;
            }

            &:last-child {
                border-left: none;
            }

            > a {
                display: flex;
                text-align: center;
                font-weight: 500;
                padding: 0;
                height: 3rem;
                font-size: 1rem;
                background: $grey-lightest;
                text-decoration: none;
                transition: all .35s;

                @media screen and (max-width: $bp-mobile) {
                    font-size: .85rem;
                    font-weight: 600;
                }

                &:hover,
                &:focus,
                &:active,
                &.active,
                &.uk-open {
                    background: $safe-blue;
                    color: #fff;
                }

                &.uk-open {
                    position: relative;
                    z-index: 1025;
                }
            }
        }
    }


    .uk-navbar-dropdown {
        width: 50%;
        background: transparent;
        box-shadow: none;
        padding: 0;
        color: $font;

        @media all and (max-width: $bp-mobile) {
            width: 66.66667%;
            font-size: 1rem;

            ul.uk-navbar-dropdown-nav {
                transform: translateX(-25%);

                &:first-child {
                    //transform: none;
                }
            }
        }
    }

    ul.uk-navbar-dropdown-nav {
        background: $safe-blue;
        padding: 1rem 0;
        overflow: hidden;
        position: relative;
        transform: none;
        box-shadow: 0 30px 18px -15px rgba(0, 0, 0, .65);
        z-index: 1030;

        li {
            a {
                color: #fff;
                text-align: left;
                display: block;
                padding: .35rem 1.5rem;

                &.active {
                    color: $safe-green;
                }
            }
        }

        > li {
            width: 100%;

            a {
                &:hover {
                    background: transparentize(#fff, .9);
                    color: $safe-green;
                }
            }

            &.sub {
                > a {
                    position: relative;
                    display: flex;
                    align-items: center;

                    &:hover {
                        color: $safe-blue;
                        background: #fff;

                        &:after {
                            color: $safe-green;
                        }
                    }

                    .uk-icon {
                        order: 3;
                        margin-left: .25rem;
                        width: 1rem;
                        transition: all .25s;
                    }

                    &:hover {
                        .uk-icon {
                            transform: translateX(1rem);
                            color: $safe-green;
                        }
                    }
                }
            }

            > ul {
                z-index: 1;
                border-left: .15rem solid #fff;
                position: absolute;
                min-height: 100%;
                display: block;
                background: $safe-green;
                width: calc(50% + .15rem);
                right: 0;
                top: 0;
                flex-direction: column;
                padding: 1rem 0;
                overflow: hidden;
                transition: all .5s;
                opacity: 1;
                transform: translateX(110%);

                li {
                    a {
                        opacity: 0;
                        transform: translateX(-3rem);
                        transition: transform .4s .45s, opacity .4s .5s, background-color .35s, color .35s;

                        &:hover {
                            color: $font;
                            background: #fff;
                        }

                        &.active {
                            color: $font;
                            background: #fff;
                        }
                    }
                }
            }

            &:hover,
            &.touched {
                &.sub {
                    > a {
                        background: #fff;
                        color: $safe-blue;
                    }
                }

                > ul {
                    opacity: 1;
                    transform: translateX(0);
                    z-index: 11;
                    transition: all .25s .25s;

                    li {
                        a{
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }
                }
            }

            &.touched {
                &.sub {
                    > a {
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

#nav-offcanvas {
    margin-left: auto;
}

.searchbar {
    flex: 1 0 25%;
    display: flex;

    .searchbar-content,
    form.form-search,
    .input-group {
        flex: 1;
        display: flex;

        &:focus-within {
            .btn-submit {
                background: $font;

                &:hover,
                &:focus,
                &:active {
                    background: $safe-green;
                }
            }
        }

        @include placeholder-white;
    }

    input {
        flex: 1;
        border: none;
        background: $grey;
        color: #fff;
        font-size: 1rem;
        padding: 0 .75rem;
    }

    .btn-submit {
        position: absolute;
        right: 0;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        background: $grey;
        appearance: none;
        border: none;
        width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .35s;

        svg {
            color: #fff;
            height: 1.1rem;
            min-width: 18px;
            min-height: 18px;
        }
    }
}

.topbar {
    display: flex;
    margin: 0;
    font-size: .75rem;
    line-height: 1;

    nav {
        height: 1.5rem;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }

    ul.nav{
        flex-wrap: nowrap;
    }


    nav.nav-top-extern {
        margin-left: 0;

        ul.nav {
            li {
                display: flex;

                &:after {
                    content: "I";
                    display: block;
                    padding: 0 .5rem;
                }

                a {
                    display: block;
                }

                &:last-child {
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }

    nav.nav-lang {
        margin-left: 1rem;

        ul.nav {
            li {
                margin-left: .25rem;

                &.hidden {
                    display: none;
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.5rem;
                    height: 1.5rem;
                    background: $grey-lightest;

                    &:hover,
                    &:focus,
                    &:active {
                        background: $safe-green;
                        color: #fff;
                    }
                }

                &:first-child {
                    margin-left: 0;
                }

                &.current {
                    pointer-events: none;

                    a {
                        color: #fff;
                        background: $grey;
                    }
                }
            }
        }
    }

    nav.nav-meta {
        margin-left: .5rem;

        a {
            color: $grey;
            margin-left: .25rem;

            &:first-child {
                margin-left: 0;
            }

            svg {
                transform: scale(.75);
                transform-origin: right center;
            }

            &:hover,
            &:focus,
            &:active {
                color: $safe-green;
            }
        }

        @media screen and (max-width: 420px) {
            display: none;
        }
    }
}


//StickyHeader


@media all and (min-width: 1200px) {
    $transY: calc((calc(100% + 3rem)) * -1);

    #main-content {
        margin-top: 7.5rem;
    }

    .stickyHeader {
        transition: transform .4s;
        margin: 0;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 100;
        background: #fff;
        width: calc(100% - 8rem);
        max-width: calc(#{$maxPagewidth} - 8rem) !important;
        will-change: transform, position;

        .topbar {
            display: none;
        }

        &.fixed {
            max-width: $maxPagewidth !important;
            width: 100%;
            transform: translate(-50%, $transY);
            position: fixed;
            top: 0;
            opacity: 0;

            .topbar {
                flex: 1 0 25%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .nav-top-extern {
                    margin-left: 1.5rem;
                    margin-right: auto;
                }

                nav.nav-meta {
                    padding-right: 1.5rem;
                }
            }

            .searchbar {
                display: none !important;
            }

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 3rem;
                left: 0;
                top: 100%;
                opacity: .2;

                @include shadow-bottom;
            }

            .page-logo.page-logo-small {
                display: block;
            }
        }
    }

    .scrollingUp {
        .stickyHeader {
            &.fixed {
                transform: translate(-50%, 0);
                opacity: 1;

                &.hideSticky {
                    transform: translate(-50%, $transY);
                }
            }
        }
    }

    .scrollingDown {
        .stickyHeader {
            &.fixed {
                &.sc_fadeout {
                    opacity: 1 !important;
                    transform: translate(-50%, $transY);
                }
            }
        }
    }
}

@media (max-width: 1279px) and (min-width: 1200px) {
    .stickyHeader.fixed .topbar .nav-top-extern {
        display: none;
    }
}


@media all and (max-width: 1199px) {
    .stickyHeader,
    #nav-offcanvas {
        display: none;
    }

    .menu-toggle {
        position: relative;
        right: 0;
        cursor: pointer;
        margin-left: auto;
        display: block;
        align-self: flex-start;
        width: 3.4em;
        height: 3.4em;
        background: $grey-lighter;
        transition: background-color .5s;
    }

    .hamburger {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        span {
            display: block;
            width: 1.3em;
            height: 2px;
            margin-bottom: 3px;
            overflow: hidden;
            position: relative;

            &:last-child {
                margin: 0;

                &:before {
                    transition-delay: 150ms;
                }

                &:after {
                    transition-delay: 150ms;
                }
            }

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: currentColor;
                transform: translateX(-200%);
                transition: all ease 300ms;
            }

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: currentColor;
                transition: all ease 300ms;
                transform: translateX(0);
            }

            &:nth-child(2) {
                &:before {
                    transition-delay: 75ms;
                }

                &:after {
                    transition-delay: 75ms;
                }
            }
        }
    }

    .cross {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        span {
            display: block;
            width: 1.3em;
            height: 2px;
            background-color: currentColor;
            transform: translateY(50%) rotate(45deg) scaleX(0);
            transition: all ease 200ms;

            &:last-child {
                transform: translateY(-50%) rotate(-45deg) scaleX(0);
            }
        }
    }

    .menu-toggle {
        color: $font;
    }

    .mm-wrapper_opened,
    .mm-wrapper_opening {
        .hamburger {
            span {
                &:before {
                    transform: translateX(100%);
                }

                &:after {
                    transform: translateX(200%);
                }
            }
        }

        .cross {
            span {
                transition-delay: 450ms;
                transform: translateY(50%) rotate(45deg) scaleX(1);

                &:last-child {
                    transform: translateY(-50%) rotate(-45deg) scaleX(1);
                }
            }
        }
    }
}

@media screen and (max-width: 599px) {
    .menu-toggle {
        position: absolute;
        right: var(--gap2);
        font-size: 12px;
    }

    .hamburger {
        span {
            display: block;
            width: 1.5em;
            height: 2px;
            margin-bottom: 3px;
            margin-top: 1px;
            overflow: hidden;
            position: relative;
        }
    }
}
