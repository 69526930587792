.boxslider {
    position: relative;

    @media screen and (max-width: 768px) {
        .uk-slider-items > * {
            opacity: .5;
            transition: opacity .35s;

            &.uk-active {
                opacity: 1;
            }
        }
    }

    @media screen and (max-width: 649px) {
        .uk-child-width-3-4 > * {
            width: 75%;
        }

        .uk-child-width-2-3 > * {
            width: 66.66667%;
        }
    }

    @media screen and (max-width: $bp-mobile-xs) {
        .uk-child-width-2-3 > * {
            width: 75%;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        .uk-slider-items > * {
            width: 50% !important;
            font-size: 1.5rem;
        }
    }

    a.boxlink {
        display: block;
        border: none;
        text-decoration: none;
        color: $font;

        h2,
        h3,
        h4 {
            transition: color .35s;

            @include lhCrop(1.5);

            will-change: color;

            a {
                display: block;
                color: $font;
            }
        }

        &:hover,
        &:focus,
        &:active {
            .news-img-wrap {
                img {
                    transform: scale(1.05);
                }
            }

            h2,
            h3 {
                color: $safe-green;
            }
        }
    }

    .sliderArrows {
        position: absolute;
        right: 0;
        top: -7rem;
        background: #fff;
        padding-left: 2rem;

        @media screen and (max-width: 767px) {
            top: -5.3em;
            margin-right: $mobilePadding;
        }

        @media screen and (min-width: 992px) {
            top: -6.7rem;
        }

        .uk-slidenav {
            &.uk-invisible {
                visibility: visible !important;
                opacity: .5;
                pointer-events: none;

                &:focus {
                    background-color: $grey-lighter;
                    color: $font;
                }
            }

            @media screen and (max-width: 767px) {
                display: none;
            }
        }

        .showAll {
            > button {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .item-content {
        background: $grey-lightest;
        position: relative;

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        .header,
        .footer {
            flex: 1 0 100%;
            max-width: 100%;
        }

        .footer {
            margin-top: auto;
            margin-bottom: -.6rem;
            border-top: 1px solid transparentize($font, .75);
            padding-top: .7rem;
            font-size: $font-small;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            a {
                border-bottom: 1px solid transparentize($font, .75);
            }
        }

        .inner-abs {
            position: absolute;
            left: 0;
            top: 0;
            padding: 1.5rem;
            width: 100%;
            height: 100%;
            overflow: hidden;

            .cat {
                @include square;
            }
        }
    }
}
