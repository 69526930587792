.boxslider.eventslider {
    article {
        @media screen and (min-width: 992px) {
            max-width: 100%;
            width: 100%;
        }
    }

    @media screen and (max-width: 991px) {
        .item-content {
            background: $grey-lightest;
        }

        .sliderArrows {
            background: #fff;

            .showAll button,
            .uk-icon.uk-slidenav {
                background: $grey-lightest;
            }
        }
    }
}

.home {
    .frame-sectionGrey {
        .col-md-6.col-sm-12.col-xs-12{
            max-width: 100%;
        }

        .col-md-4.col-sm-12.col-xs-12 {
            @media screen and (min-width: 992px) and (max-width: 1199px) {
                padding-left: 2.3rem !important;
            }

            @media screen and (max-width: 991px) {
                position: relative;
                margin-top: 4rem;
                padding-top: 2rem !important;

                &:before {
                    content: "";
                    width: 100vw;
                    height: calc(100% + 4rem);
                    background: #fff;
                    position: absolute;
                    transform: translateX(-1rem);
                    left: 0;
                    top: 2rem;
                }
            }

            @media screen and (max-width: 767px) {
                &:before {
                    transform: translateX(3rem);
                }
            }

            > div:first-child {
                @media screen and (min-width: 1200px) {
                    max-width: 66.66667%;
                    margin-left: auto;
                }
            }

            header {
                h1,
                .h1 {
                    color: $safe-blue;
                }

                @media screen and (min-width: 992px) {
                    display: inline-block;
                    position: relative;
                    z-index: 10;

                    h1 {
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            .news-teaser__date {
                &:before {
                    background: $safe-blue;
                }
            }

            .uk-dotnav > * > :focus,
            .uk-dotnav > * > :hover {
                background-color: $safe-green;
            }

            .uk-dotnav > .uk-active > * {
                background-color: $safe-blue;
            }
        }
    }
}



