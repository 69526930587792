.container,
.row {
    &:before,
    &:after {
        content: none;
    }
}

[class*="col-"],
.container {
    padding: 0 var(--gap2);
}

.container {
    padding: 0;
    max-width: 100%;
}

.contentcol-content {
    padding: 0;
    min-height: 0 !important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    //margin: calc(#{var(--gap)} * -1);
    [class*="col-"] {
        flex: 1 0 100%;
        max-width: 100%;

        + [class*="col-"] {
            @media screen and (max-width: 767px) {
                margin-top: 1rem;
            }
        }
    }

    @for $i from 1 through 12 {
        [class*="col-xs-#{$i}"] {
            flex: $i 0 (100% / 12 * $i);
            max-width: (100% / 12 * $i);
        }

        [class*="col-xs-#{$i}"]:last-child {
            flex: 0 0 (100% / 12 * $i);
            max-width: (100% / 12 * $i);
        }
    }

    @media (min-width: 768px) {
        @for $i from 1 through 12 {
            [class*="col-sm-#{$i}"] {
                flex: $i 0 (100% / 12 * $i);
                max-width: (100% / 12 * $i);
            }

            [class*="col-sm-#{$i}"]:last-child {
                flex: 0 0 (100% / 12 * $i);
                max-width: (100% / 12 * $i);
            }
        }
    }

    @media (min-width: 992px) {
        @for $i from 1 through 12 {
            [class*="col-md-#{$i}"] {
                flex: $i 0 (100% / 12 * $i);
                max-width: (100% / 12 * $i);
            }

            [class*="col-md-#{$i}"]:last-child {
                flex: 0 0 (100% / 12 * $i);
                max-width: (100% / 12 * $i);
            }
        }
    }

    @media (min-width: 1200px) {
        @for $i from 1 through 12 {
            [class*="col-lg-#{$i}"] {
                flex: $i 0 (100% / 12 * $i);
                max-width: (100% / 12 * $i);
            }

            [class*="col-lg-#{$i}"]:last-child {
                flex: 0 0 (100% / 12 * $i);
                max-width: (100% / 12 * $i);
            }
        }
    }
}

html > body {
    @include bs2uk-grid;
}

