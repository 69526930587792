
@mixin grid-small {
    .uk-grid > * {
        padding-left: 1.5rem;
    }

    .uk-grid {
        margin-left: -1.5rem;
    }
}

@mixin bs2uk-grid {
    .row {
        max-width: none;
        width: auto;
        margin: 0 0 0 -3rem;

        [class*="col-"]:not(.contentcol-content) {
            padding: 0 0 0 3rem !important;
        }
    }
}

@mixin sectionFullwidth {
    padding: 0 $pagePadding;

    @media screen and (min-width: 768px) {
        margin: 0 $pagePadding-neg;
        width: auto;
        max-width: none;
    }

    @media screen and (max-width: 767px) {
        padding-left: $mobilePadding;
        padding-right: $mobilePadding;

        header,
        section {
            h1,
            .h1 {
                padding-left: 0;
            }
        }
    }
}

@mixin link{
    color: $safe-blue;
    border-bottom: 1px solid transparentize($safe-blue, .75);

    &:hover,
    &:focus,
    &:active {
        color: $safe-green;
        border-color: transparentize($font, .5);
    }
}

@mixin square {
    font-size: 1em;
    line-height: 1.2;
    font-weight: 800;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 1.25rem;

    &:before {
        content: "";
        display: block;
        flex: 1 0 100%;
        max-width: .9em;
        height: .9em;
        background: $safe-green;
        margin-right: .9em;
    }
}

@mixin sectionHeadline {
    color: $safe-blue;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 2.75em;
    line-height: .9;
    letter-spacing: -.05em;
    margin-bottom: 4.5rem;
    margin-top: 6rem;
}

@mixin lhCrop($line-height) {
    &:before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        margin-top: calc((1 - #{$line-height}) * .5em);
    }
}

@mixin shadow-bottom {
    pointer-events: none;
    $shadowcolor:#000;

    background: transparent;
    background: linear-gradient(180deg, rgba(0, 0, 0, .55) 0, rgba(0, 0, 0, .25) 25%, transparent 88%, transparent);
}

@mixin placeholder-white {
    $input-placeholder-white: #fff;

    input::-webkit-input-placeholder {
        color: $input-placeholder-white !important;
    }

    input::-moz-placeholder {
        color: $input-placeholder-white !important;
    }

    input::-ms-placeholder {
        color: $input-placeholder-white !important;
    }

    input::placeholder {
        color: $input-placeholder-white !important;
    }
}
