.boxes {
    margin-top: 3rem;

    > .col {
        display: flex;

        @media screen and (min-width: 960px) {
            flex: 1 0 30%;
            max-width: 33.33334%;
        }

        @media screen and (min-width: 1200px) {
            flex: 1;
            max-width: 33.33334%;
        }

        @media screen and (max-width: 959px) {
            flex: 1 0 50%;
            max-width: 50%;
        }

        @media screen and (max-width: 767px) {
            flex: 1 0 100%;
            max-width: 100%;

            .box-square {
                min-height: 0 !important;
            }
        }
    }

    .box-square {
        display: block;
        padding: 1.5rem;

        @media screen and (max-width: 1199px) {
            font-size: $font-small;
        }

        header {
            * {
                text-transform: uppercase;
                font-weight: 800;
            }
        }

        .article,
        article {
            padding: 0 0 .5rem;
            margin-bottom: .5rem;
            border-bottom: 1px solid transparentize($font, .5);
            font-size: $font-medium;

            &:last-child {
                border: none;
                margin-bottom: 0;
                padding: 0;
            }

            .news-list-date,
            .news-teaser__date {
                margin-bottom: .5rem;

                @media screen and (max-width: 1199px) {
                    font-size: $font-small;
                }
            }
        }
    }

    .list-news-small {
        .article {
            .header {
                font-size: $font-small;
            }

            .news-list-date,
            .news-teaser__date {
                font-weight: 700;
            }

            font-size: $font-medium;

            a {
                display: block;
                border: none;
                color: $font;
                &:hover{
                    color: $safe-green;
                }
            }
        }
    }

    .frame-type-mmthemesafe_asidemenu {
        font-size: $font-small;

        header > *:first-child {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:after {
                content: "";
                display: block;
                background: url("../../Images/icons/icon-list-grey.svg") no-repeat center center;
                background-size: contain;
                min-width: 1em;
                min-height: 1em;
                margin-left: .5em;
            }
        }

        nav {
            ul.uk-nav.nav-list {
                li {
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &:after {
                            content: "";
                            display: block;
                            background: url("../../Images/icons/chevron-right_grey.svg") no-repeat center center;
                            background-size: contain;
                            min-width: .75em;
                            min-height: .75em;
                            margin-left: .5em;
                        }
                    }
                }
            }
        }
    }
}

.boxes-bottom,
.boxes-aside{
    position: relative;
    padding: 0 0 3rem;
    margin-top: 0;
    flex: 1 0 100%;

    @media screen and (min-width: 768px) {
        margin-bottom: -6rem;
    }

    .frame{
        margin-bottom: 0 !important;

        p:last-child{
            margin-bottom: 0;
        }
    }

    &:before {
        background: $grey-lightest;
        content: "";
        position: absolute;
        z-index: 1;
        width: 100vw;
        max-width: $maxPagewidth;
        left: 50%;
        margin-left: -50vw;
        height: 100%;

        @media screen and (min-width: $maxPagewidth) {
            margin-left: calc(#{$maxPagewidth} / -2);
        }
    }

    .boxes {
        > .col {
            position: relative;
            z-index: 10;
        }

        @media screen and (min-width: 960px) and (max-width: 1199px) {
            > .col {
                &:nth-child(4) {
                    display: none;
                }
            }
        }

        .box-square {
            background: #fff;
        }

        .news-list-view .article {
            background: #fff;
        }
    }
}

.boxes-aside {
    .box-square {
        display: block;
    }

    @media screen and (max-width: 1199px) {
        .boxes{
            padding-top: 3rem;
            margin-top: 0;
        }
    }

    @media screen and (min-width: 1200px) {
        &:before{
            content: none;
        }

        .boxes{
            > .col{
                flex: 1 0 100%;
                max-width: 100%;
            }

            .box-square{
                background: $grey-lightest;
            }
            .news-list-view .article {
                background: $grey-lightest;
            }
        }
    }
}
