.publicationslider {
    h2,
    .h2 {
        font-size: 1em;
        font-weight: 400;
        line-height: 1.5;
        margin: 0;
        hyphens: auto;

        a {
            display: block;
            hyphens: auto;

            &:hover,
            &:focus,
            &:active {
                color: $safe-green;
            }
        }
    }

    .item-content {
        .inner-abs {
            display: flex;
            flex-wrap: wrap;
        }

        .footer{
            border: none;

            .publication-source{
                flex: 1 0 100%;
                border-top: 1px solid transparentize($font, .75);
                font-size: $font-small;
                font-weight: 700;
                line-height: 1.25;
                padding: .7rem 0 .4rem;
            }
        }

        .source,
        .date,
        .keywords {
            display: none;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        .item {
            width: 33.33333%;
        }
    }
}
