.uk-accordion {
    margin-bottom: .75rem;
    padding-bottom: .75rem;
    width: 100%;
}

.uk-accordion-title {
    font-size: 1rem;
    padding: .75em;
    font-weight: 600;
    border: none;
    background: $grey-lightest;

    &:before {
        margin-right: 10px;
        margin-left: 0;
        float: left;
    }
}
