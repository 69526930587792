#main-content {
    > .breadcrumb-wrapper:first-child {
        @media screen and (min-width: 1200px){
            margin-top: -1.5rem;
        }
    }
}

.uk-breadcrumb {
    li:last-child {
        pointer-events: none;

        a {
            color: $font;
        }
    }
}
