$safe-green: #8ac400;
$safe-blue: #185da9;
$safe-blue-light: #5e8dc3;
$grey-dark: #7f7f7f;
$grey: #aaa;
$grey-lighter: #ebebeb;
$grey-lightest: #f4f4f4;
$font: $grey-dark;

$mobileHeader: 74px;
$mm_menuMinWidth: 320px !important;
$mm_menuMaxWidth: 540px;

.mm-wrapper_opening [class*=mm-menu_pagedim].mm-menu_opened ~ .mm-wrapper__blocker {
    opacity: 1;
}

.mm-menu_opened.mm-menu_pagedim ~ .mm-wrapper__blocker {
    background: transparent;
}

#MobileMenuWrap {
    transform: translateZ(0);

    &.mm-menu {
        background: #fff;
        color: $font;
    }

    .mm-panel,
    .mm-panels {
        background: #fff !important;

        a,
        a:link {
            color: $font;

            &:hover,
            &:focus,
            &:active {
                color: $safe-green;
                background: $grey-lightest;
            }

            &.mm-navbar__title {
                font-weight: 800;
                color: #fff;
                text-transform: uppercase;
                font-size: 1.25em;

                > span {
                    overflow: visible;
                    font-size: 1em;
                }
            }
        }
    }

    .mm-navbar {
        min-height: 0;

        > * {
            min-height: 0;
        }
    }

    .mm-navbars_top {
        .mm-navbar {
            &:first-child {
                background: $safe-green;
                height: 3em;
                font-weight: 800;
                font-size: 1.25em;
                text-transform: uppercase;

                a,
                a:link {
                    color: #fff;
                }
            }

            .input-group {
                height: 3em;
            }
        }
    }

    .mm-navbars_bottom {
        .mm-navbar {
            height: 3em;

            .topbar {
                justify-content: space-between;
                padding: 0 1.5em;
                font-size: calc(10px + .15em);

                nav.nav-top-extern {
                    a {
                        color: $font;
                        font-size: 1em;

                        &:hover,
                        &:active {
                            color: $safe-green;
                        }
                    }
                }

                nav.nav-lang {
                    margin-left: auto;

                    li.current a {
                        color: #fff;
                    }
                }
            }

            #nav-close-btn {
                display: none;
            }
        }
    }

    .mm-listitem {
        padding: 0;

        a {
            font-size: calc(.15em + 12px);
            padding: 1.5em;
        }

        &:after {
            left: 0;
        }

        &.active {
            a {
                color: $safe-green;
                font-weight: 600;
            }
        }
    }

    span.mm-listitem__text {
        font-weight: 700;
    }

    .mm-listitem__btn {
        padding-right: 39px;
    }

    .mm-counter {
        min-width: 25px;
        font-size: 1.2em;
        line-height: 1.35;
        color: $font;
        display: none;
    }

    .mm-listview {
        li a {
            border: none;
        }

        .mm-listitem__btn:not(.mm-listitem__text) {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 4.25em;
            margin: 0;
            padding: 0;
            background: #fff;

            &:hover,
            &:active {
                background: $safe-green;
            }
        }

        .mm-btn {
            &:after {
                content: url(../../Images/icons/chevron-right.svg);
                opacity: .65;
                width: .5em;
                height: 1.4em;
                border: none;
                margin: 0;
                line-height: initial;
                transform: none;
                position: relative;
                right: auto;
            }
        }
    }

    .mm-btn:after,
    .mm-btn:before {

    }

    .mm-btn_next:after,
    .mm-btn_prev:before {
        width: 5px;
        height: 5px;
        border-width: 1px;
        border-color: transparent;
        border-left-color: #fff;
        border-top-color: #fff;
    }

    .mm-btn_next {
        &:after {
            right: 13px;
        }

        &:before {
            content: none;
        }
    }

    .mm-btn_prev {
        &:before {
            width: 7px;
            height: 7px;
            border-width: 2px;
        }

        &:after {
            content: none;
        }
    }
}

@media all and (max-width: 767px) {
    #MobileMenuWrap {
        .mm-navbars_top .mm-navbar {
            &:first-child {
                font-size: 1em;
            }
        }

        .mm-panels {
            font-size: .55rem;
        }

        .mm-listitem a {
            font-size: calc(12px + .25em);
            padding: .75em 1.5em;
        }

        .mm-listview {
            .mm-btn {
                &:after {
                    line-height: initial;
                }
            }
        }
    }
}

@media all and (min-height: 600px) {
    #MobileMenuWrap {
        .mm-menu {
            font-size: 1.8em;

            .mm-panels {
                a:hover {
                    background: $grey-lightest;
                }

                a:not(.mm-btn_next) {
                    &:hover {
                        color: $safe-green;
                    }

                    &:active {
                        transition-duration: .05s;
                        color: #fff;
                        background: $safe-green;
                    }
                }
            }
        }

        .mm-navbar__title {
            font-size: 1em;
        }

        .mm-listitem__btn,
        .mm-listitem__text {
            padding: 1rem 3rem 1rem 1.5em;
        }

        .mm-listitem__btn {
            padding-right: 3em;
        }

        .mm-btn_next:after {
            right: 1.9em;
        }

        .mm-listview .mm-btn:before {
            right: 5em;
        }
    }
}


