.uk-grid {
    flex: 1 0 100%;
    width: auto;
    max-width: none;
}

.uk-link,
a {
    color: $font;
    transition: all .35s;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: $safe-green;
        text-decoration: none;
    }
}

.uk-thumbnav > * > :after {
    background: transparent;
}

.uk-navbar-item,
.uk-navbar-nav > li > a,
.uk-navbar-toggle {
    min-height: 0;
    text-transform: none;
    font-family: $basefont;
}

$sliderButton: 2rem;

.sliderArrows {
    display: flex;

    .uk-dropdown {
        background: transparent;
        box-shadow: none;
        padding: 0;
        min-width: 0;
        width: 13.25rem;

        .uk-dropdown-nav {
            position: relative;
            padding: 0;
            background: $safe-green;

            .uk-nav-divider {
                border-color: transparentize(#fff, .5);
            }

            li {
                padding: 0;
                margin: 0;

                a {
                    padding: .5rem;
                    color: #fff;

                    &:hover {
                        background: transparentize(#fff, .85);
                        border: none;
                        color: #fff;
                    }
                }
            }
        }

        @media screen and (min-width: 768px) {
            .uk-dropdown-nav {
                left: calc(50% - calc(#{$sliderButton} / 2));
            }
        }
    }
}

.uk-nav-default {
    font-size: 1.05rem;
}

.uk-slidenav,
.showAll button {
    padding: 0;
    display: flex;
    justify-content: center;
    background: $grey-lightest;
    border: none;
    width: $sliderButton;
    height: $sliderButton;
    color: $font;
    transition: all .35s;
    margin-left: .25rem;

    @media screen and (max-width: 991px) {
        min-width: 40px;
        min-height: 40px;
    }

    svg {
        max-width: .6em;
    }

    &.uk-open,
    &:hover,
    &:active {
        background: $safe-green !important;
        color: #fff !important;
        opacity: 1 !important;
    }
}

.uk-slidenav {
    align-items: center;
    position: relative;

    svg {
        display: none;
    }

    &:before,
    &:after {
        content: url(../../Images/icons/chevron-right.svg);
        opacity: .6;
        width: .4rem;
        min-width: 9px;
        height: 1rem;
        border: none;
        margin: 0;
        line-height: 1;
        transition: opacity .35s;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:after {
        opacity: 0;
        content: url(../../Images/icons/chevron-right_white.svg);
    }

    &:hover,
    &:focus,
    &:active {
        &:before {
            opacity: 0;
        }

        &:after {
            opacity: 1;
        }
    }

    &.uk-slidenav-previous {
        &:before {
            content: url(../../Images/icons/chevron-left.svg);
        }

        &:after {
            content: url(../../Images/icons/chevron-left_white.svg);
        }
    }
}

.showAll {
    button {
        margin: 0;

        svg {
            max-width: 1em;
            min-width: 16px;
        }
    }
}

.uk-dotnav {
    justify-content: flex-end;
    margin-top: 3rem;

    > * > * {
        border: none !important;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.uk-icon-button {
    transition: all .35s;

    svg {
        width: 1.25rem;
    }
}

.uk-breadcrumb {
    li a {
        border: none !important;
    }

    > :nth-child(n+2):not(.uk-first-column):before {
        margin: 0 calc(.5rem - 4px) 0 .5rem;
    }
}

.breadcrumb-wrapper {
    display: none;
    flex: 1 0 100%;
}

.has-breadcrumb {
    .breadcrumb-wrapper {
        display: block;
    }
}
