.uk-grid {
    aside {
        align-self: flex-start;

        @media screen and (min-width: 1200px) and (min-height: 800px) {
            position: sticky;
            top: 3rem;
            transition: all .5s;

            .scrollingUp & {
                top: 6rem;
            }
        }
    }
}

@media all and (max-width: 1199px) {
    .uk-grid {
        aside {
            + main {
                order: -1;
            }
        }
    }
}

#main-content {
    > aside {
        width: 100%;

        > .frame:not(.frame-type-mmthemesafe_widgetbox) {
            display: flex;
            margin-left: -3rem;

            > .frame.frame-type-mmthemesafe_widgetbox {
                flex: 1;
                padding-left: 3rem;
                display: flex;
            }
        }
    }
}
