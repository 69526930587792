.tx-mmpublications {
    td span.separator:last-child,
    .author-categories span.separator:last-child {
        display: none;
    }

    table.table-striped {
        > tbody > tr > td {
            border-top: 0;
        }
    }
}

