.ce-gallery {
    .ce-inner {
        display: flex;
        margin-left: -1rem;
    }

    .ce-row {
        padding: 0 1rem;

        figure {
            margin-bottom: 2rem;
            overflow: hidden;
            border: .5rem solid $grey-lighter;
            transition: border-color .35s;

            a {
                display: block;
                transition: transform .8s;
            }

            &:hover {
                border-color: $safe-green;

                a {
                    transform: scale(1.1);
                }
            }
        }
    }
}
