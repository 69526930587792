#countDown {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    line-height: 1;
    font-weight: 600;
    color: $safe-green;
    margin-bottom: 3rem;

    &.blueTxt {
        color: $safe-blue;

        div {
            border-right: 1px solid $safe-blue;
        }
    }

    &.greenBG {
        background: $safe-green;
        color: #fff;
        padding: 1rem;

        div {
            border-right: 1px solid #fff;
        }
    }

    &.blueBG {
        background: $safe-blue;
        color: #fff;
        padding: 1rem;

        div {
            border-right: 1px solid #fff;
        }
    }

    div {
        padding: 0 10px;
        text-align: center;
        font-size: 2.4rem;
        border-right: 1px solid $safe-green;

        &:last-child {
            border: none;
        }

        span {
            display: block;
            font-size: .8rem;
            padding-top: 5px;
            font-weight: 400;
        }
    }
}
