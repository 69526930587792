.dataTables_wrapper {
    @media screen and (max-width: 659px) {
        max-width: calc(100vw - 3rem);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .wrapper_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 7px;
        background: $grey-lighter;
        padding: .75rem 1rem;
        font-size: $font-small;

        .dataTables_filter{
            flex: 1;
            background: transparent;

            label{
                font-size: 0;
                padding-left: 0;
                display: flex;

                &:before{
                    content: "";
                    display: block;
                    background: url("../../Images/icons/search.svg") no-repeat .2rem 50%;
                    background-size: 1rem;
                    width: 2rem;
                    opacity: .55;
                }

                input{
                    width: 100%;
                }
            }
        }

        @media screen and (max-width: 479px) {
            .dataTables_length {
                display: none;
            }

            .dataTables_filter {
                flex: 1 0 100%;
            }

            label {
                width: 100%;
                display: flex;
                align-items: center;

                select {
                    flex: 1;
                }
            }
        }

        &:empty {
            padding: 0;
        }

        select,
        input {
            background: #fff;
            margin: 0 .5rem;
            padding: .25rem .5rem;
        }

        > * {
            display: flex;
            align-items: center;
        }
    }

    .wrapper_bot {
        margin-top: -1.5rem;
    }

    .dataTables_filter {
        input {
        }
    }

    .dataTables_length {
    }
}

table.dataTable {
    thead {
        tr {
            th {
                &[class*="sorting"] {
                    cursor: pointer;
                    transition: background-color .35s;

                    &:hover {
                        background-color: $safe-blue;
                    }
                }

                &[class*="sorting_"]:not(.sorting_disabled) {
                    cursor: pointer;
                    background-color: $safe-green;
                }

                &[class*="sorting_"]:not(.sorting_disabled),
                &.sorting:not(.sorting_disabled) {
                    background-image: url("../../Images/icons/sorting.svg");
                    background-position: right .5rem center;
                    background-size: 1em 1em;
                    background-repeat: no-repeat;
                    padding-right: 2rem;
                }

                &.sorting_disabled {
                    pointer-events: none;
                    background-image: none;
                }
            }
        }
    }

    tbody {
        tr {
            transition: background-color .75s;
            height: 1px;

            &:hover {
                background: transparentize($safe-green, .75);
            }

            td {
                vertical-align: top;
                height: inherit;

                &[class*="sorting_"] {
                    background-color: transparentize($safe-green, .75);
                }
            }
        }
    }

    .dt-author {
        font-weight: 500;

        a,
        span {
            margin-right: .5rem;
        }

        a {
            @include link;
        }
    }

    .dt-title {
        .authorWrapper {
            text-align: center;

            img {
                display: block;
                width: 100%;
                height: auto;
                max-width: 100px;
                margin: 0 auto 10px;
            }
        }

        font-weight: 400;

        a,
        a strong,
        h4 {
            font-weight: 600 !important;

            @include link;

            border: none;
        }

        h4 {
            margin: 0;
            padding: 0;
            font-size: 100%;
        }

        .title-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 100%;

            @media screen and (max-width: 1199px) {
                padding: 0;
            }
        }



        .dt-source {
            font-size: $font-small;
            font-weight: 400;
            padding-top: .5rem;
            margin-top: .5rem;
            border-top: 1px solid transparentize($font, .75);

            @media screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
    }

    .dt-cat {
        font-weight: 300;
    }

    .dt-published,
    .dt-year,
    .dt-fundby {
        text-align: center;
    }

    .dt-keywords {
    }

    &.table-rotated-header {
        th {
            vertical-align: bottom;
            background-position: bottom .65em right .5em !important;
            padding-bottom: .65em;
            font-size: .85rem;
        }

        td.news-category {
            vertical-align: middle;
        }

        td.news-category span.funding-dot {
            display: block;
            background: $safe-blue;
            border: 8px solid $safe-blue;
            width: 2px;
            height: 2px;
            border-radius: 50px;
            margin: auto;
        }

        th.rotate {
            white-space: nowrap;

            &.sorting,
            &.sorting_asc,
            &.sorting_desc {
                background-position: center bottom .6em !important;
                padding: 1rem !important;
                min-width: 4em;

                > div {
                    transform: translateY(-1.2em) rotate(270deg);
                    width: 1em;
                    min-height: 12em;
                    transform-origin: 6.6em center;
                    line-height: 0;
                    margin: 3.3em 0 1em .6rem;

                    @media screen and (max-width: 1199px) {
                        margin-left: .25rem;
                    }

                    > span {
                        padding: 0;
                    }
                }
            }
        }
    }

    &.dataTable-author{

    }
}

.dataTables_paginate {
    ul.pagination {
        @media screen and (min-width: 768px) {
            justify-content: flex-end;
        }
    }
}

.topic-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
    background: $grey-lighter;

    &:before {
        content: "";
        display: block;
        background: url("../../Images/icons/search.svg") no-repeat 1rem 50%;
        background-size: 1rem;
        width: 2rem;
        height: 3rem;
        opacity: .55;
    }

    input {
        flex: 1;
        padding: 1rem;
        background: $grey-lighter;
    }
}

.panel-topics {
    margin-bottom: 1.5rem;

    .panel-heading {
        display: none;
    }

    .panel-collapse {
        height: auto !important;

        .panel-body {
            display: flex;
        }

        .dataTables_wrapper {
            display: block;
        }
    }

    .dataTables_wrapper {
        .wrapper_top {
            display: none;
        }

        .wrapper_bottom {
            display: block;
        }
    }

    table.table-topics.dataTable {
        display: flex;
        border-spacing: 0;
        margin: 0 0 2rem;
        height: 60vh;
        max-height: 600px;
        min-height: 300px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        @media screen and (max-width: 991px){
            max-height: 300px;
        }

        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 14px;
        }

        &::-webkit-scrollbar-track {
            background: $grey-lightest;
            border-left: 7px solid #fff;
        }

        &::-webkit-scrollbar-thumb {
            background: $font;
            border-left: 7px solid #fff;
            cursor: ns-resize;
            transition: background-color .35s;

            &:hover {
                background-color: $safe-green;
            }
        }

        thead {
            display: none;
        }

        tr,
        td {
            display: block;
            height: auto;
            padding: 0 0 .5rem;
            text-align: left;
            background: transparent !important;
        }

        tr {
            display: flex;
            flex-wrap: wrap;
            background: $grey-lightest !important;
            border-bottom: .5rem solid #fff;
            padding: .75rem 1rem 1.5rem;
            flex: 1 0 100%;

            @media screen and (max-width: 991px) {
                min-height: 0;
            }

            &:last-child {
                border: none;
            }

            &.odd {
                background: #fff;
            }

            td.first-child{
                padding-top: 0;
            }
        }

        td {
            flex: 1 0 100%;

            &.dt-published {
                @include square;

                margin-bottom: 0;
                padding-top: 0;
            }

            &.dt-title {
                margin-bottom: 0;

                a,
                h4,
                h4 a,
                a h4 {
                    transition: all .35s;
                    line-height: 1.5;
                }
            }

            &.dt-author {
                margin: auto 0 -.5rem;
                border-top: 1px solid transparentize($font, .75);
                padding: .5rem 0 0;
                font-size: $font-small;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            .publication-source{
                font-size: $font-small;
                margin-top: .25rem;
            }
        }
    }

    .dataTables_paginate {
        ul.pagination {
            justify-content: flex-start;

            li {
                flex: 1;
            }
        }
    }
}

.col-md-6.col-sm-12.col-xs-12{
    .dataTable-author{
        border-spacing: 0;
        margin: 0 0 2rem;
        width: 100%;

        + .wrapper_bot{
            .dataTables_paginate ul.pagination{
                justify-content: flex-start;
            }
        }
    }
}

.tx_mmpublications {
    .data-table-author-wrapper {
        table.dataTable-author {
            display: block;

            thead {
                display: block;

                tr {
                    flex: 1 0 100%;
                    display: flex;

                    th {
                        flex: 1;
                        max-width: 50%;
                        padding: .5rem 1rem;
                        height: 2rem;
                        &:first-child{
                            max-width: calc(50% - 3.5px);
                        }
                    }
                }
            }
            tbody {
                display: block;
                height: calc(60vh - 2rem);
                max-height: calc(600px - 2rem);
                min-height: calc(300px - 2rem);
                overflow: auto;
                -webkit-overflow-scrolling: touch;

                @media screen and (max-width: 991px) {
                    max-height: calc(300px - 2rem);
                }

                scrollbar-width: thin;

                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    background: #fff;
                }

                &::-webkit-scrollbar-thumb {
                    background: $font;
                    cursor: ns-resize;
                    transition: background-color .35s;

                    &:hover {
                        background-color: $safe-green;
                    }
                }

                tr {
                    width: 100%;
                    display: flex;
                    height: auto;

                    td {
                        flex: 1 0 50%;
                        align-items: center;
                        display: flex;
                        width: 50%;
                        padding: 1rem;
                    }
                }
            }
        }
    }
}
