#header {
    flex: 1 0 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 1.5rem;

    @media screen and (max-width: 767px) {
        padding: $mobilePadding 0 0 0;
        font-size: 2vw;
        overflow: hidden;
    }
}

.header-top {
    display: flex;
    align-items: flex-end;
    flex: 1;

    .navbar-toggle {
        display: none;
    }
}

.page-logo {
    margin-right: 1.5rem;

    a {
        width: 7em;
        display: block;
        transform-origin: left bottom;

        &:hover{
            transform: scale(1.05);
        }
    }

    &.page-logo-small {
        margin-right: 3px;
        display: none;

        a {
            width: 3em;
        }
    }

    .bg-blue {
        fill: #185da9 !important;
    }

    .letter {
        fill: #fff;
    }

    .bg-green {
        fill: #8ac400 !important;
    }

    g {
        opacity: 0;
    }

    .S-Group {
        opacity: 0;
        animation: S forwards .2s .5s;
    }

    .A-Group {
        opacity: 0;
        animation: A forwards .2s .7s;
    }

    .F-Group {
        opacity: 0;
        animation: F forwards .2s .9s;
    }

    .E-Group {
        opacity: 0;
        animation: E forwards .2s 1.1s;
    }

    @keyframes S {
        0% {
            opacity: 0;
            transform: translateY(-52%);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes A {
        0% {
            opacity: 0;
            transform: translateX(-52%);
        }

        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes F {
        0% {
            opacity: 0;
            transform: translateY(-52%);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes E {
        0% {
            opacity: 0;
            transform: translateX(-52%);
        }

        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

#page-title {
    a {
        display: block;

        &:hover {
            color: $font;

            .department-row.title-large {
                color: $safe-green;
            }
        }
    }

    .department-wrap {
        font-size: 1.4em;
        line-height: 1.3;
        font-weight: 300;
        overflow: hidden;

        @media screen and (min-width: 600px) {
            height: 2.25em;
            padding-top: 1em;
            animation: department-wrap .35s 2s forwards;
        }
    }

    .department-row {
        font-size: 80%;
        transform: translateX(-100%);
        animation: page-title .6s 1.45s forwards;

        &.title-large {
            font-weight: 600;
            font-size: 100%;
            transition: color .35s;
        }
    }
}

@keyframes page-title {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes department-wrap {
    0% {
        padding-top: 1em;
    }

    100% {
        padding-top: 0;
    }
}

.stickyHeader {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
}

@media screen and (max-width: 599px) {
    .page-logo {
        margin-right: 1rem;
    }

    #page-title {
        flex: 1 0 100%;

        .department-row {
            display: none;

            &.title-large {
                display: block;
                font-size: 3.5vw;
                width: 45vw;
                line-height: 1.1;
                margin-bottom: -.1em;
            }
        }
    }
}

