/* open-sans-300 - latin */
@font-face {
    font-family: 'Safe Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../Fonts/open-sans-v17-latin-300.eot');
    src:
        local('Open Sans Light'),
        local('OpenSans-Light'),
        url('../../Fonts/open-sans-v17-latin-300.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/open-sans-v17-latin-300.woff2') format('woff2'),
        url('../../Fonts/open-sans-v17-latin-300.woff') format('woff'),
        url('../../Fonts/open-sans-v17-latin-300.ttf') format('truetype'),
        url('../../Fonts/open-sans-v17-latin-300.svg#OpenSans') format('svg');
}

/* open-sans-300italic - latin */
@font-face {
    font-family: 'Safe Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../../Fonts/open-sans-v17-latin-300italic.eot');
    src:
        local('Open Sans Light Italic'),
        local('OpenSans-LightItalic'),
        url('../../Fonts/open-sans-v17-latin-300italic.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/open-sans-v17-latin-300italic.woff2') format('woff2'),
        url('../../Fonts/open-sans-v17-latin-300italic.woff') format('woff'),
        url('../../Fonts/open-sans-v17-latin-300italic.ttf') format('truetype'),
        url('../../Fonts/open-sans-v17-latin-300italic.svg#OpenSans') format('svg');
}

/* open-sans-regular - latin */
@font-face {
    font-family: 'Safe Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../../Fonts/open-sans-v17-latin-regular.eot');
    src:
        local('Open Sans Regular'),
        local('OpenSans-Regular'),
        url('../../Fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/open-sans-v17-latin-regular.woff2') format('woff2'),
        url('../../Fonts/open-sans-v17-latin-regular.woff') format('woff'),
        url('../../Fonts/open-sans-v17-latin-regular.ttf') format('truetype'),
        url('../../Fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg');
}

/* open-sans-600 - latin */
@font-face {
    font-family: 'Safe Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../../Fonts/open-sans-v17-latin-600.eot');
    src:
        local('Open Sans SemiBold'),
        local('OpenSans-SemiBold'),
        url('../../Fonts/open-sans-v17-latin-600.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/open-sans-v17-latin-600.woff2') format('woff2'),
        url('../../Fonts/open-sans-v17-latin-600.woff') format('woff'),
        url('../../Fonts/open-sans-v17-latin-600.ttf') format('truetype'),
        url('../../Fonts/open-sans-v17-latin-600.svg#OpenSans') format('svg');
}

/* open-sans-600italic - latin */
@font-face {
    font-family: 'Safe Sans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../../Fonts/open-sans-v17-latin-600italic.eot');
    src:
        local('Open Sans SemiBold Italic'),
        local('OpenSans-SemiBoldItalic'),
        url('../../Fonts/open-sans-v17-latin-600italic.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/open-sans-v17-latin-600italic.woff2') format('woff2'),
        url('../../Fonts/open-sans-v17-latin-600italic.woff') format('woff'),
        url('../../Fonts/open-sans-v17-latin-600italic.ttf') format('truetype'),
        url('../../Fonts/open-sans-v17-latin-600italic.svg#OpenSans') format('svg');
}

/* open-sans-italic - latin */
@font-face {
    font-family: 'Safe Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../../Fonts/open-sans-v17-latin-italic.eot');
    src:
        local('Open Sans Italic'),
        local('OpenSans-Italic'),
        url('../../Fonts/open-sans-v17-latin-italic.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/open-sans-v17-latin-italic.woff2') format('woff2'),
        url('../../Fonts/open-sans-v17-latin-italic.woff') format('woff'),
        url('../../Fonts/open-sans-v17-latin-italic.ttf') format('truetype'),
        url('../../Fonts/open-sans-v17-latin-italic.svg#OpenSans') format('svg');
}

/* open-sans-700 - latin */
@font-face {
    font-family: 'Safe Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../../Fonts/open-sans-v17-latin-700.eot');
    src:
        local('Open Sans Bold'),
        local('OpenSans-Bold'),
        url('../../Fonts/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/open-sans-v17-latin-700.woff2') format('woff2'),
        url('../../Fonts/open-sans-v17-latin-700.woff') format('woff'),
        url('../../Fonts/open-sans-v17-latin-700.ttf') format('truetype'),
        url('../../Fonts/open-sans-v17-latin-700.svg#OpenSans') format('svg');
}

/* open-sans-700italic - latin */
@font-face {
    font-family: 'Safe Sans';
    font-style: italic;
    font-weight: 700;
    src: url('../../Fonts/open-sans-v17-latin-700italic.eot');
    src:
        local('Open Sans Bold Italic'),
        local('OpenSans-BoldItalic'),
        url('../../Fonts/open-sans-v17-latin-700italic.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/open-sans-v17-latin-700italic.woff2') format('woff2'),
        url('../../Fonts/open-sans-v17-latin-700italic.woff') format('woff'),
        url('../../Fonts/open-sans-v17-latin-700italic.ttf') format('truetype'),
        url('../../Fonts/open-sans-v17-latin-700italic.svg#OpenSans') format('svg');
}

/* open-sans-800 - latin */
@font-face {
    font-family: 'Safe Sans';
    font-style: normal;
    font-weight: 800;
    src: url('../../Fonts/open-sans-v17-latin-800.eot');
    src:
        local('Open Sans ExtraBold'),
        local('OpenSans-ExtraBold'),
        url('../../Fonts/open-sans-v17-latin-800.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/open-sans-v17-latin-800.woff2') format('woff2'),
        url('../../Fonts/open-sans-v17-latin-800.woff') format('woff'),
        url('../../Fonts/open-sans-v17-latin-800.ttf') format('truetype'),
        url('../../Fonts/open-sans-v17-latin-800.svg#OpenSans') format('svg');
}

/* open-sans-800italic - latin */


@font-face {
    font-family: 'Safe Sans';
    font-style: italic;
    font-weight: 800;
    src: url('../../Fonts/open-sans-v17-latin-800italic.eot');
    src:
        local('Open Sans ExtraBold Italic'),
        local('OpenSans-ExtraBoldItalic'),
        url('../../Fonts/open-sans-v17-latin-800italic.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/open-sans-v17-latin-800italic.woff2') format('woff2'),
        url('../../Fonts/open-sans-v17-latin-800italic.woff') format('woff'),
        url('../../Fonts/open-sans-v17-latin-800italic.ttf') format('truetype'),
        url('../../Fonts/open-sans-v17-latin-800italic.svg#OpenSans') format('svg');
}
