.frame-type-textpic {
    margin-bottom: 3rem;

    p {
        @include lhCrop(1.75);
    }

    img {
        max-width: none;
        width: 100%;
        display: block;
        margin-bottom: 1.5rem;
    }
}
