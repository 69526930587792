body,
html,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
nav li a {
    font-family: $basefont;
}

.sr-only {
    display: none;
}

::-moz-selection {
    background: $safe-green;
}

::selection {
    background: $safe-green;
}

h1,
.h1 {
    @include lhCrop(1.25);

    font-weight: 600;
    color: $safe-blue;
    font-size: 2.25rem;
    line-height: 1.25;
    text-transform: none;
    letter-spacing: 0;
    margin: 0 0 3rem;

    small {
        font-size: 100% !important;
    }
}

article {
    h1,
    .h1 {
        + h2,
        + .h2 {
            margin-top: 0;
            color: $font;
        }
    }
}

article, .article{
    .item{
        a{
            @include link;

            margin-right: .25em;
        }
    }
}

.home {
    header {
        h1,
        .h1 {
            display: flex;
            align-items: center;

            @include sectionHeadline;

            color: $safe-green;
            position: relative;

            &:after {
                content: "";
                flex: 1;
                height: 1px;
                background: #d4d4d4;
                margin: 0 0 0 1.5rem;
            }

            @media screen and (max-width: 767px) {
                padding-left: $mobilePadding;
                font-size: 2.75em;
                margin-bottom: 3rem;
                margin-top: 3rem;

                &:after {
                    content: none;
                }
            }

            @media screen and (max-width: $bp-mobile-xs) {
                font-size: 2.25em;
            }
        }
    }

    h2 {
        color: $font;

        a {
            color: $font;

            &:hover,
            &:active {
                color: $safe-green;
            }
        }
    }
}

* + h3,
.h3 {
    margin-top: 3.236rem;
}

h2,
.h2 {
    margin-top: 0;
    font-size: 1.5rem;
    margin-bottom: 1em;
    line-height: 1.45;
    font-weight: 400;
    color: $safe-green;

    a {
        color: $safe-green;

        &:hover,
        &:active {
            color: $safe-blue;
        }
    }

    + h3,
    + .h3 {
        margin-top: -1em;
    }
}

h3,
.h3 {
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 1.618rem;

    + h4,
    + .h4 {
        margin-top: -.81em;
    }

    + p {
        margin-top: 0;
    }
}

h4,
.h4 {
    margin-top: 0;
    font-size: 1.05rem;
    line-height: 1.25;
    font-weight: 600;
    margin-bottom: 1.618rem;
}



p {
    font-size: 1.05rem;
    line-height: 1.75;
    margin-bottom: 1.75em;

    a {
        @include link;
    }

    > img {
        margin-bottom: 1.75rem;
        padding-right: 0 !important;
        max-width: 100% !important;
        width: auto !important;
    }
}

#main-content {
    ol li > a,
    ul:not(.nav):not([class*="-nav"]):not(.uk-accordion):not(.pagination):not(.tx-indexedsearch-browsebox),
    ol {
        font-size: 1.05rem;
        line-height: 1.75;
        margin-bottom: 1.75em;

        li > a {
            @include link;
        }
    }
}


#footer ul,
nav,
ul.nav {
    li a {
        border: none;
    }
}

.uk-hr,
hr {
    margin: 1.5rem 0;
}

