.news-list-date,
.news-teaser__date {
    @include square;
}

*:not(.news-single) .news-text-wrap {
    background: #fff;
    position: relative;
    z-index: 3;
    margin-top: -6rem;
    padding: 3rem;

    p {
        &:first-child {
            &:first-letter {
                /* stylelint-disable */
                initial-letter: 2;
                /* stylelint-enable */
                font-weight: 700;
                text-transform: uppercase;
                color: #fff;
                line-height: 1;
                font-size: 1.78rem;
                padding: .2em .4em;
                float: left;
                margin: .205em .5em 0 0;
                height: 1em;
                display: block;
                background: $safe-green;
            }
        }
    }
}

.news-search-form {
    .input-group {
        display: flex;
        margin-bottom: 1.5rem;
    }

    input.search-query {
        min-width: 33.33333%;
        border: none;
        font-size: 1rem;
        padding: 0 .75rem;
        height: 3rem;
        background: $grey-lightest;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .btn-submit {
        height: 3rem;
        cursor: pointer;
        width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .35s;
        background: url(../../Images/icons/search_white.svg) $grey no-repeat 50%;
        background-size: 1rem;

        &:hover,
        &:focus,
        &:active {
            background-color: $safe-green;
        }
    }
}

.uk-width-expand.main {
    .news-list-view {
        @media screen and (min-width: 1200px) {
            max-width: 66.66667%;
        }
    }
}

.news-list-view {
    &.list-news {
        > div:first-child {
            > ul.pagination {
                display: none;
            }
        }
    }

    .article {
        margin-bottom: 3rem;
        padding: 1.5rem;
        background-color: $grey-lightest;

        @media screen and (max-width: 767px) {
            margin-bottom: 1.5rem;

            [class*="col-"] {
                flex: 1 0 100%;

                .news-img-wrap {
                    margin-bottom: 1.5rem;
                }
            }
        }
    }

    h3 {
        margin-bottom: 1rem;
    }

    h3,
    h3 a {
        color: $safe-blue;
        margin-top: 0;
        font-size: 1.25rem;

        &:hover {
            color: $safe-green;
        }
    }

    .news-body {
        display: flex;
        flex-wrap: wrap;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .news-img-wrap {
        &:empty {
            display: none;
        }

        > a {
            display: block;
            margin-right: 1rem;
        }

        img {
            width: 100%;
            max-width: none;
        }
    }

    .col-sm-4 {
        .news-img-wrap {
            margin: 0;
        }
    }

    .teaser-text {
        flex: 1;
    }

    .footer {
        margin-bottom: -.6rem;
        padding-top: 1rem;
        margin-top: 1rem;
        border-top: 1px solid transparentize($font, .75);

        p {
            font-weight: 600;
            font-size: $font-small;
        }

        a {
            font-weight: 400;
            font-size: $font-small;

            &:first-child {
                margin-left: .5rem;
            }
        }
    }

    ul.pagination {
        li:first-child {
            &.disabled {
                display: none;
            }
        }
    }
}

.news-single {
    .article {
        .news-text-wrap {
            margin: 0;
            padding: 0;
            background: none;
        }

        .video-embed {
            padding-bottom: 56.25%;
            margin: 0 0 1.5rem;
            position: relative;
            background: #000;
            color: #fff;

            .cookieconsent-optout-marketing {
                padding: 20% 10%;
                text-align: center;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                height: 100%;
                width: 100%;

                span {
                    cursor: pointer;
                }
            }

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                border: 0;
            }
        }

        .header{

        }

        .footer{

        }
    }
}
