section {
    flex: 1 0 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;

    &.container {
        padding: 0;
    }

    > main,
    > div,
    > header,
    > .header,
    .frame {
        max-width: 100%;
        flex: 1 0 100%;
    }

    &.home {
        .frame{
            max-width: none;
        }
    }
}

#main-content {
    > .frame {
        padding-bottom: 6rem;
    }

    main {
        > *:last-child {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    &.page{
        @media screen and (max-width: 1199px){
            margin-top: 3rem;

            h1{
                margin-bottom: 1.5rem;
            }
        }
    }
}

[class*="sectionblue"] {
    background-color: $safe-blue;

    @include sectionFullwidth;

    @media screen and (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;

        header h1 {
            padding-left: $mobilePadding;
        }
    }

    header h1 {
        color: #fff !important;

        &:after {
            background: transparentize(#fff, .7);
        }
    }

    .boxslider {
        .uk-slidenav:after,
        .uk-slidenav:before {
            opacity: 1;
        }

        .sliderArrows {
            background: $safe-blue;
        }
    }

    .uk-slidenav,
    .showAll button {
        background: $safe-blue-light;
        color: #fff;
    }

    .uk-dotnav > li:not(.uk-active) > * {
        background: $safe-blue-light;

        &:hover {
            background: #fff;
        }
    }

    .uk-slidenav {
        &:before,
        &:after {
            content: url(../../Images/icons/chevron-right_white.svg);
        }

        &.uk-slidenav-previous {
            &:before,
            &:after {
                content: url(../../Images/icons/chevron-left_white.svg);
            }
        }
    }
}

[class*="sectionGrey"] {
    background-color: $grey-lightest;

    @include sectionFullwidth;

    @media screen and (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;

        header h1 {
            padding-left: $mobilePadding;
        }
    }

    header h1 {
        &:after {
            background: transparentize($font, .7);
        }
    }

    .boxslider {
        .item-content {
            background: #fff;
        }

        .sliderArrows {
            background: $grey-lightest;
        }
    }

    .uk-slidenav,
    .showAll button {
        background: #fff;
    }
}
