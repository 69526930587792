ul.pagination,
ul.tx-indexedsearch-browsebox {
    display: flex;
    margin: 0;
    padding: 0;
    font-size: $font-medium !important;

    li {
        flex: 1 0 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 3em;
        height: 3em;
        list-style: none;
        margin-right: 6px;
        font-size: 1em;
        font-weight: 600;

        &:last-child {
            margin-right: 0;
        }

        a,
        span {
            height: 3em;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            background: $grey-lightest;
            border: none !important;
        }

        a {
            transition: all .35s;

            &:hover,
            &:focus,
            &:active {
                color: #fff !important;
                background: $safe-blue;
            }
        }

        &.active,
        &.tx-indexedsearch-browselist-currentPage {
            span,
            strong,
            a {
                background: $safe-green;
                color: #fff;
                pointer-events: none;
            }

            strong {
                width: 100%;
            }
        }

        &.previous,
        &.next {
            a {
                position: relative;
                overflow: hidden;
                color: transparent;

                &:before {
                    color: $font;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    text-align: center;
                    content: ">";
                    font-size: 1em;
                }

                &:hover,
                &:focus,
                &:active {
                    color: transparent !important;

                    &:before {
                        color: #fff;
                    }
                }
            }
        }

        &.previous {
            a {
                &:before {
                    content: "<";
                }
            }

            &.disabled {
                display: none;
            }
        }

        &.next {
            &.disabled {
                display: none;
            }
        }

        &.disabled {
            pointer-events: none;

            a {
                background: transparent;
            }
        }
    }
}

.dataTables_paginate{
    ul.pagination li{
        max-width: 2.25em;

        a{
            height: 2.25em;
        }
    }
}
