$breakpoint-small: 640px; // Phone landscape
$breakpoint-medium: 960px; // Tablet Landscape
$breakpoint-large: 1200px; // Desktop
$breakpoint-xlarge: 1600px; // Large Screens

$grid-gutter-horizontal-l: 3rem;
$grid-gutter-vertical-l: 1.5rem;
$grid-gutter-horizontal: 1.5rem;
$grid-gutter-vertical: 1.5rem;
$grid-medium-gutter-horizontal: 1.5rem;
$grid-medium-gutter-vertical: $grid-medium-gutter-horizontal;
$grid-small-gutter-horizontal: .75rem;
$grid-small-gutter-vertical: $grid-small-gutter-horizontal;
$base-hr-margin-vertical: 1.5rem;
$padding-small-padding: 1.5rem;
$base-margin-vertical: 1.5rem;
$global-link-color: $safe-green;

$global-small-margin: 1.5rem;
$global-medium-margin: 3rem;
$global-large-margin: 4.5rem;
$global-xlarge-margin: 6rem;

$global-primary-background: $safe-blue;
$global-success-background: $safe-green;
$global-warning-background: $safe-green;
$global-danger-background: $safe-green;


$base-heading-color: $font;
$base-small-font-size: $font-small;
$base-em-color: $safe-green;

$dotnav-margin-horizontal: .5rem;
$dotnav-item-width: 1rem;
$dotnav-item-height: 1rem;
$dotnav-item-border-radius: 0;
$dotnav-item-background: $grey-lighter;
$dotnav-item-active-background: $safe-green;
$dotnav-item-hover-background: $safe-blue;

$icon-button-size: 2.5rem;
$icon-button-size: 2.5rem;
$icon-button-border-radius: 0;

$dropdown-padding: .5rem;
$dropdown-nav-item-color: $font;
$dropdown-nav-item-hover-color: $safe-green;
$nav-item-padding-vertical: .5rem;
$nav-item-padding-horizontal: .5rem;
$nav-default-item-color: $font;
$nav-default-item-hover-color: $safe-blue;
$nav-default-item-active-color: $safe-green;
$nav-sublist-item-padding-vertical: .25rem;

$breadcrumb-item-color: $grey;
$breadcrumb-divider-color: $breadcrumb-item-color;
$breadcrumb-item-hover-color: $safe-green;
$breadcrumb-divider-margin-horizontal: .5rem;

$accordion-title-font-size: 1.25rem;
$accordion-title-line-height: 1.25;
$accordion-title-color: $font;
$accordion-title-hover-color: $safe-green;
$accordion-item-margin-top: .5rem;

$base-em-color: $font;
