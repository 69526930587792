.box-square {
    flex: 1;
    max-width: 100%;
    min-height: 0;
    overflow: hidden;
    background: $grey-lightest;
    position: relative;
    transition: min-height .5s;
    margin-bottom: 3rem;
    box-sizing: content-box;
    display: none;

    @media screen and (min-width: 1200px) {
        display: block;
    }

    &.bottom {
        align-self: flex-end;
    }

    &[class*="widget-box"] {
        display: flex;
        flex-wrap: wrap;
    }

    .box-content,
    .box-footer {
        position: relative;
        padding: 1.5rem;
        max-width: 100%;

        ul.nav li {
            flex: 1 0 100%;
        }
    }


    .box-header,
    header {
        text-transform: uppercase;
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 900;
        display: flex;
        flex: 1 0 100%;
        max-width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem;

        h1,
        .h1, .h2, h2{
            flex: 1 0 100%;
            font-size: 100%;
            color: $font;
            padding: 0 !important;
            margin: 0 !important;
            text-transform: uppercase;
            line-height: 1;
            font-weight: 900;
        }

        + .box-content {
            padding-top: 0;
        }

        .headline {
            display: block;
            width: 100%;
            overflow: hidden;
            hyphens: auto;
        }

        .icon {
            display: flex;
            align-items: center;
        }
    }

    header{
        padding: 0 0 1.5rem;

        + *{
            margin-top: 0 !important;
        }
    }

    h4,
    .h4,
    .h5,
    h5{
        text-transform: uppercase;
        font-weight: 900;
        font-size: 1rem;
        border-bottom: 1px solid transparentize($font, .5);
        padding-bottom: .5rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;

        strong,
        b {
            font-weight: 900;
        }

        em{
            text-transform: none;
            font-weight: 700;
        }
    }

    p{
        line-height: 1.25;
        font-size: 1rem;
        margin: 0 0 1em;
    }

    @media screen and (max-width: 1199px) {

    }
}

nav.box-square {
    .box-header {
        .icon {
            background: url("../../Images/icons/icon-list-grey.svg") no-repeat right;
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}

.container.events{
    @media screen and (max-width: 991px) and (min-width: 600px) {
        .row {
            display: flex;
            flex-wrap: wrap;

            .col-md-8.infoTable,
            .col-md-16{
                flex: 1 0 50%;
                width: 50%;
            }
        }

        .eventOverview{
            .col-sm-12 {
                width: 100%;
                flex: 1 0 100%;
            }
        }
    }

    @media screen and (max-width: 599px) {
        .row {
            display: flex;
            flex-wrap: wrap;

            .col-md-8.infoTable{
                flex: 1 0 100%;
                width: 100%;
            }
        }

        .eventOverview{
            .col-sm-12 {
                width: 100%;
                flex: 1 0 100%;
            }
        }
    }
}
