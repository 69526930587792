.btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    min-width: 12rem;
    min-height: 3rem;
    font-size: 1rem;
    line-height: 1.1;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    padding: 0 1.5rem;
    background: $grey-lightest;
    margin: 1rem 0;

    span {
        display: block;
    }

    + .btn {
        margin-left: 1.5rem;
    }

    svg {
        min-width: 1.75rem;
    }

    &.btn-icon {
        text-align: left;
        padding-right: 1rem;

        span {
            padding-right: 1rem;
        }

        .icon {
            color: $safe-green;
            transition: all .35s;
        }

        &:hover {
            .icon {
                transform: translateX(.5rem);
            }
        }

        &.btn-download {
            &:hover {
                .icon {
                    transform: translateY(.25rem);
                }
            }
        }

        &.btn-back {
            padding-left: 1rem;
            padding-right: 1.5rem;

            span {
                padding-right: 0;
            }

            .icon {
                order: -1;
                transform: scale(-1);
            }

            &:hover {
                .icon {
                    transform: scale(-1) translateX(.5rem);
                }
            }
        }
    }
}

.btn-group {
    display: flex;

    .btn {
        flex: 1;
        max-width: 33.33334%;
    }
}

@media screen and (max-width: 649px) {
    .btn-group {
        display: block;
    }

    .btn {
        margin-bottom: 0;
        width: 100%;
        max-width: 100%;
        flex: 1 0 100%;

        + .btn {
            margin-left: 0;
        }
    }
}
