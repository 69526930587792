input,
button,
textarea,
select,
fieldset {
    font-family: $basefont;
    color: $font;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 0 !important;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}

form {
    position: relative;
}

button {
    background: $grey-lightest;
}

select,
input[type="search"] {
    background: $grey-lightest;
    padding: .5rem;
    font-size: $font-small;
}

.select-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: .5rem;
    background: $grey-lightest;
    padding: .5rem;
    font-size: $font-small;

    @media screen and (max-width: 767px){
    }
}

.select-wrapper {
    position: relative;
    display: flex;
    margin-right: .5rem;
    padding: .5rem;
    background: #fff;
    max-width: 10rem;

    &:after {
        content: "";
        position: absolute;
        right: .75rem;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        background: url("../../Images/icons/chevron-down.svg") no-repeat center;
        opacity: .5;
        background-size: 100%;
        width: 10px;
        height: 15px;
        pointer-events: none;
    }

    select {
        cursor: pointer;
        padding: 0 1rem 0 0;
        margin: 0;
        background: transparent;
        width: 100%;
    }

    @media screen and (max-width: 1350px) {
        flex: 1;
        max-width: none;
    }
}

button.reset {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    transition: all .35s;
    margin-left: auto;
    background: #aaa;
    color: #fff;
    width: 2.1rem;
    min-width: 30px;

    svg {
        width: 1.5rem;
    }

    &:hover {
        color: #fff;
        background: $safe-green;
    }
}

//Newsletter iFrame ===============//
#form {
    max-width: 500px;

    @media screen and (max-width: 1023px) {
        max-width: 100%;
    }

    margin-bottom: 3rem;

    input,
    button,
    textarea,
    select,
    fieldset {
        border: 1px solid $grey-lightest;
        width: 100%;
        padding: .5em;
        transition: border-color .3s;
        resize: none;

        &:focus {
            border-color: transparentize($font, .5);
        }

        &[type="submit"] {
            cursor: pointer;
            border: none !important;
            padding: 1em;
            transition: all .3s;
            text-transform: uppercase;
            font-weight: 700;

            &:hover,
            &:focus {
                background: $safe-green;
                color: #fff;
            }
        }
    }

    label {
        transition: color .3s;
        font-weight: 400;

        &:hover,
        &:focus {
            color: $safe-blue;

            input[type="checkbox"],
            input[type="radio"] {
                background: $safe-blue;
                color: #fff;
            }
        }

        &.attributedataprotection {
            cursor: pointer;
            font-size: 11px;
            font-weight: 300;
            line-height: 1.1;
        }
    }

    input {
        &[type="checkbox"],
        &[type="radio"] {
            transition: all .35s;
            background: $grey-lighter;
            width: 1.5em;
            height: 1.5em;
            margin-right: 1em;
            display: flex;
            align-items: center;
            line-height: 0;
            justify-content: center;
            text-align: center;
            border: 0 !important;

            &:checked {
                background: $safe-green;
                color: #fff;
            }
        }

        &[type="checkbox"] {
            &:before {
                content: "✓";
                display: block;
            }
        }

        &[type="radio"] {
            &:before {
                content: "•";
                display: block;
            }
        }
    }

    table {
        font-size: $font-small;
        border: 0;
        border-spacing: 0;

        tbody {
            label {
                display: flex;
                align-items: center;
                line-height: 1.2;

                input,
                span {
                    cursor: pointer;
                }
            }

            tr {
                th {
                    text-align: left;
                    max-width: 200px;
                }

                td {
                    padding: .25em 0 .25em .5em;

                    label {
                        margin-bottom: .25em;
                    }
                }

                &:first-child,
                &:last-child {
                    th {
                        vertical-align: top;
                    }
                }
            }
        }

        @media screen and (max-width: 420px) {
            display: block;

            tbody {
                display: block;

                tr {
                    display: block;
                    margin-bottom: 1em;

                    th,
                    td {
                        display: block;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

#WebToLeadForm {
    input.btn.btn-primary {
        background-color: $safe-blue;
        color: #fff;
        text-align: center;
        min-width: auto;
        transition: background-color .2s;
        padding: 0 2.5rem;
        &:hover {
            background-color: $safe-green;
        }
    }
}
