body.tx-indexsearch {
    main {
        width: 100%;
        max-width: 1000px;
    }
}

.tx-indexedsearch-searchbox {
    fieldset {
        display: inline-block;
        background: $grey-lightest;
        padding: 1.5rem;
        margin-bottom: 3rem;

        @media screen and (max-width: 767px) {
            display: block;
        }
    }

    legend {
        display: none;
    }

    label {
        text-transform: uppercase;
        font-weight: 600;
        font-size: $font-medium;
        display: block;
        margin-bottom: .5rem;
    }
}

.tx-indexedsearch-form{

}

input.tx-indexedsearch-searchbox-sword {
    margin-bottom: .5rem;
}

input.tx-indexedsearch-searchbox-sword,
.tx-indexedsearch-searchbox-button {
    width: 18.8rem;
    max-width: 100%;
    display: block;
    padding: .5rem;
    font-size: $font-medium;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.tx-indexedsearch-searchbox-button {
    background: $safe-green;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color .35s;

    &:hover,
    &:focus {
        background: $safe-blue;
    }
}

.tx-indexedsearch-info-sword,
.tx-indexedsearch-browsebox p {
    display: none;
}

.tx-indexedsearch-res {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid transparentize($font, .7);

    h2,
    h3 {
        margin-bottom: 1rem;

        a {
            color: $safe-green;

            &:hover,
            &:focus {
                color: $safe-blue;
            }
        }
    }

    p.tx-indexedsearch-description {
        margin: 0 !important;
        font-size: $font-medium;
    }
}
